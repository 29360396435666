import Skeleton from 'react-loading-skeleton';
import React from 'react';

const Page = React.lazy(()=>import('./PhoneInput'))

export default function LazyPhoneInput(props){
    return <React.Suspense fallback={<Skeleton height={36.5} />}>
        <Page {...props} />
    </React.Suspense>
}

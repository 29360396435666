import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  langsMap,
} from '../../translations/localehandler';
import Dropdown from './Dropdown';
import {getItem, setItem} from '../../tools/storage';

function LangsSelector(props){
    const [locale, setLocale] = useState(props.locale)
    useEffect(()=>{
        setLocale(props.locale)
    },[props.locale])
    const  onChange = useCallback((value) => {
        setLocale(value)
        setItem('locale', value);
        document.location.reload();
    },[])

        const items = Object.keys(langsMap).map((key) => {
            return {value: key, label: langsMap[key]}
        }).filter((i)=>{
            if(props.available_locales && Array.isArray(props.available_locales) && props.available_locales.length > 0) {
                return props.available_locales.map(({value})=>value).indexOf(i.value) !== -1
            }else{
                return true
            }
        })
        return (<>
                <Dropdown onSelected={onChange} direction={"up"} opts={items} selected={locale} />
            </>
        );
}

function mapStateToProps(state) {
    return {
        locale: getItem("locale") || state.settings.locale,
        available_locales: state.settings.available_locales,
    }
}


export default connect(mapStateToProps)(LangsSelector);

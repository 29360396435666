import React from 'react';
import Youtube from '../../../cmp/Youtube';

function YoutubeVideoComponent({videoId})  {
        return (
            <div className="d-flex justify-content-center">
                <div className="col-lg-8">
                    <Youtube videoId={videoId}/>
                </div>
            </div>
        );
}

export default YoutubeVideoComponent;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {get} from '../api';
import {buildRefLink} from '../tools/functions';
import ShareBar from '../components/cmp/ShareBar';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import RenderUserSocials from '../components/cmp/RenderUserSocials';
import {FiShare} from 'react-icons/fi';
import useToggle from '../components/cmp/hooks/useToggle';
import {updateImageUrl} from '../tools/func';
import {Helmet} from 'react-helmet';
import LoadingSkeleton from '../components/cmp/LoadingSkeleton';

const uncategorized = {
  name: 'Uncategorized',
  slug:'',
  _id:'',
}

function ProductLinkHistoryShare(props) {
  const website = useSelector((state)=>state.settings.website)
  const productLinkHistoryBanner = useSelector((state)=>{
    return  state.settings.aff_product_link_history_banner_url || 'https://i.imgur.com/I28NkD1.jpg'
  })
  const params = useParams();
  const [error, setError] = useState(null)
  const [links, setLinks] = useState(null)
  const [affiliate, setAffiliate] = useState(null)
  const [category, setCategory] = useState(null)
  const [categories, setCategories] = useState([])
  const [categoryItems, setCategoryItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  useEffect(()=>{
    setLoading(true)
    get('/favorite-links', {
      ref_code : params.ref_code
    }).then((data)=>{
      setLoading(false)
      if(data.categories){
        setCategories(data.categories || [])
      }
      if(data.category_links){
        setCategoryItems(data.category_links)
      }
      if(data.error){
        setError(data.error)
      }else{
        setLinks(data.links.map((i)=>{
          return {
            ...i,
            ref_link: buildRefLink(data.ref_code || params.ref_code, i.url),
          }
      }))

        setCategory(data.category)
        setAffiliate(data.affiliate)
      }
    })
  },[params.ref_code])

  const ref_link = useMemo(()=>{
    if(affiliate && affiliate.ref_code && website) {
      return buildRefLink(affiliate.ref_code, website)
    }
  },[website, affiliate])
  return (
      <div className="min-vh-100 bg-light">
        <Helmet><title>{affiliate?.name || ''}'s page</title></Helmet>
        <div className="container">
        <div className={"bg-white border-left border-right border-bottom"} style={{borderBottomLeftRadius:'var(--bs-border-radius)', borderBottomRightRadius:'var(--bs-border-radius)'}}>
          <img src={loading ? null : (affiliate?.wishlist_banner?.url || productLinkHistoryBanner)} alt=""
               className={"link-history-top-banner"}
               style={{width:'100%', maxHeight:280, objectFit:'cover'}}
          />
          <div className={"position-relative"}>
            {
              affiliate ? <>
                {
                  affiliate.profile_photo ? <img src={updateImageUrl(affiliate.profile_photo, {width:360, height:360})}
                            className={"link-history-avatar"}
                        /> : null
                }</>  : null
            }
            <div className={"link-history-user"}>
              <div className="d-flex justify-content-between align-items-start pe-2">
                  <div>
                    <h2>
                      {
                        affiliate && affiliate.name ? <>{affiliate?.name}</>
                            : null
                      }
                    </h2>
                    <div>{affiliate?.wishlist_bio}</div>
                    <RenderUserSocials affiliate={affiliate} />
                  </div>
                  <ShareBarToTheSide ref_link={ref_link} />
              </div>
            </div>


          </div>
        </div>
      <div className={"container  border py-4 bg-white my-4"} style={{borderRadius:'var(--bs-border-radius)'}}>
        <RenderCategoryTitle categories={categories} categoryItems={categoryItems}  />
        {
          loading ? <div className="mb-2">
            <LoadingSkeleton height={36} count={5}/>
          </div>: null
        }

        <div className="row row-cols-2 row-cols-md-3 g-2 row-cols-lg-4">
          <RenderItemsForCategory links={links} categoryItems={categoryItems} categories={categories} />
        </div>

        <RenderMore name={affiliate?.name} categories={categories} />
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-2">
          <RenderCategories categories={categories} links={links} items={categoryItems} />
        </div>
      </div>

        </div>

      </div>
  );
}

function ShareBarToTheSide({ref_link}){
  const [share, toggleShare] = useToggle()
  if(!share) return <button className={"btn"} onClick={toggleShare}>
    <FiShare />
  </button>

  return <ShareBar noCopy link={ref_link}/>
}

function RenderMore({name, categories}){
  const [searchParams] = useSearchParams()
  const category = searchParams.get('category') || "";
  const remaining = categories.filter(({slug})=>slug !== category)
  if(!category || remaining.length <= 0 || !name){
    return null;
  }else{
    return <>
      <hr/>
      <h5><strong>More from {name}</strong></h5>
    </>
  }
}
function RenderCategoryTitle({categories, categoryItems}){
  const [searchParams] = useSearchParams()
  const category = searchParams.get('category') || "";
  useEffect(() => {
    setTimeout(()=>{
      window.scrollTo({
        top:0,
        behavior:'smooth'
      })
    },1)
  }, [category])
  const data = useMemo(()=>{
    if(category !== undefined){
      const found = categories.find((i)=>i.slug==category)
      if(found){
        return {
          name: found.name,
          items: categoryItems.filter(({category_id})=>category_id == found._id).length
        }
      }
    }
  },[category, categories, categoryItems])
  if(!data) return null;
  return <>
    <div className="text-center pb-4 pt-2">
      <h3 style={{fontFamily:'serif'}}>{data.name}</h3>
      <h6><FormattedMessage id={"X Items"} values={{count:data.items}} /></h6>
    </div>
  </>
}
function RenderItemsForCategory({categories, categoryItems, links}){
  const [searchParams] = useSearchParams()
  const category = searchParams.get('category') || "";

  const filteredItems = useMemo(()=>{
    if(category !== undefined){
        const found = categories.find((i)=>i.slug==category)
        if(!found) return []
        const linkIds = categoryItems.filter(({category_id})=>category_id == found._id)
        .map((i)=>i.link_id)
        return links.filter(({id})=>linkIds.indexOf(id) > -1)
      }
  },[category, categories, categoryItems, links])
  return   filteredItems && filteredItems.filter(({image})=>image).map((i)=>{
    return <div className="col text-body">
      <a href={i.ref_link}
         target={"_blank"} rel={"noreferrer noopener"}
         className="card-link d-block mb-4 flex-grow-1 flex-shrink-1">
        <img src={i.image}
           loading={"lazy"}
           style={{width: '100%',
            aspectRatio:'1/1'}} className={"rounded bg-light object-cover hover-contain"} alt="" />
          <div className="p-1 fs-6 text-body">
            <div>{i.title}</div>
          </div>
      </a>
    </div>
  })
}


function RenderCategories({categories, items, links}){
  const [searchParams] = useSearchParams()
  const category = searchParams.get('category') || "";
  const remaining = categories.filter(({slug})=>slug !== category)
  return <>
    {
      remaining.map((i)=>{
        const category_links = items.filter(({category_id})=>category_id == i._id)
            .map(({link_id})=>link_id);
        const first_link = links.find(({id})=>category_links.indexOf(id) > -1)

        return <Link to={`?category=${i.slug}`} className={"col"}><div className={"position-relative rounded border overflow-hidden"}>
          <div style={{position:'absolute', inset:0,
            opacity:0.2,
            backgroundColor:"#4158D0",
            backgroundImage: "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
            // backgroundBlendMode: "multiply,multiply",
          }}/>
          <div style={{position:'absolute',
              bottom:0, left:0, right:0, padding:8,
              textShadow: "-1px -1px 15px #fff, 1px -1px 0 #fff, -1px 1px 15px #fff, 1px 1px 15px #fff"
          }} className={"text-body"}>
            <div><small><FormattedMessage id={"X Items"}
                                          values={{
                                            count: category_links.length
                                          }}
                                          /></small></div>
            <strong>{i.name}</strong>
          </div>
          <img
              className={""}
              src={first_link?.image || 'https://via.placeholder.com/1x1'} alt=""
              style={{width:'100%',aspectRatio:'1/1', objectFit: 'cover'}} />
  </div>
        </Link>
      })
    }
  </>
}

function CategoryItem({name, slug}){
  const [searchParams,setSearchParams] = useSearchParams();
  const isActive = useMemo(()=>{
    return searchParams.get('category') === slug;
  },[searchParams])

  const onClick = useCallback(()=>{
    if(isActive){
      searchParams.delete('category')
      setSearchParams(searchParams)
    }else {
      setSearchParams({
        category: slug,
      })
    }
  },[isActive, searchParams])
  return <button onClick={onClick}
                 className={`btn rounded border mr-2 mb-2 ${isActive ? 'btn-primary' : 'btn-light'}`}>
    {name}
  </button>
}


export default ProductLinkHistoryShare;


import React from 'react';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import LogoRender from '../cmp/LogoRender';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {post, removeAccessToken} from '../../api';
import {useNavigate} from 'react-router-dom';

function ManagedAccountsPage(props) {
  const managed_accounts = useSelector((state)=>state.settings.metadata ? JSON.parse(state.settings.metadata)?.managed_accounts : null)
  const name = useSelector((state)=>state.settings.name)
  if(!managed_accounts) return <p>No accounts to manage</p>
  const accessAccount = (id)=>()=>{
      post('/access_managed_account',{
        account_id: id
      }).then((data)=>{
        if(data.access_token){
          window.open(`/login-as/${data.access_token}`)
        }
      })
  }
  const navigate = useNavigate()
  const handleLogout = () => {
    removeAccessToken();
    navigate('/logout');
  }

  return (
      <div className={"container"}>
      <div className={"row pt-4"}>
        <FormattedHelmet id={'Managed accounts'}/>
        <div className="col-md-3"/>
        <div className="col-md-6">
          <div className={'text-center mb-4'}>
            <LogoRender/>
          </div>
          <h2 className={'text_on_bg text-center'}><FormattedMessage
              id={'Choose an account'}/></h2>
          <div className="card">
            <ul className="list-group list-group-flush">
              {
                managed_accounts.map((i)=> {
              return <button onClick={accessAccount(i.value)} className="list-group-item">
                  <div className="d-flex justify-content-between">
                      <span>{i.label}</span>
                      <span className={"font-monospace"}>ID: {i.value}</span>
                    </div>
              </button>
                })
              }
              </ul>
          </div>
        </div>
        <div className="mt-4 text-center">
          <button className={"btn btn-link"} onClick={handleLogout}>
            <FormattedMessage id={"logout"}/></button>
        </div>
      </div>

      </div>

  );
}

export default ManagedAccountsPage;

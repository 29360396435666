import React from 'react';
import {FormattedMessage} from 'react-intl';

function Button(props){
    const cls = "btn " + props.className;
    const { loading, saved, title} = props;
    const btnProps = {...props};
    delete btnProps.loading;
    delete btnProps.saved

    return (
        <button {...btnProps} disabled={props.disabled || loading || saved} className={cls}>
            {
                loading && <span className="spinner-border spinner-border-sm"
                                 style={{verticalAlign:'middle'}}
                                 role="status" aria-hidden="true"/>
            }
            {!loading && !saved && props.children}
            {
                saved && <span>Saved</span>
            }
            {
                !loading && title && <FormattedMessage id={title} />
            }
        </button>
    )
}

export default Button;

import React, {useEffect} from 'react';
import useQuery from '../../../cmp/hooks/useQuery';
import {getAccessToken, post} from '../../../../api';
import Spinner from '../../../cmp/Spinner';
import {useNavigate} from 'react-router-dom';
import mustache from 'mustache';
import {useSelector} from 'react-redux';

function StripeOnCallback(){
    const {session_id, plan_id} = useQuery()
    const settings = useSelector((state)=>state.settings)
    const navigate = useNavigate()
    useEffect(()=>{
        if(session_id && plan_id){
            post('/paymentplans/activate',{
                data:{
                    session_id,
                },
                plan_id,
                payment_gateway:'stripe',
                }).then((data)=>{
                if(data.success){
                    window.location = data.after_payment_redirect
                        ? mustache.render(data.after_payment_redirect, settings) :
                        "/?payment_success=true";
                }else{
                    navigate("/choose_plan",{replace:true})
                }
            })
        }
    },[session_id, plan_id])
    if(!getAccessToken()){
        return <p className={"text-danger"}>Error: You are currently not logged in.</p>
    }
    return <div className="d-flex min-vh-100 align-items-center justify-content-center">
        <Spinner size={"lg"}/>
    </div>;
}

export default StripeOnCallback

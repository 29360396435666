import React from 'react';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        isEmbedded: state.settings.isEmbedded
    };
}

export default connect(mapStateToProps)(({children, isEmbedded}) => {
    return (
        <>
        <div className={`d-flex justify-content-center align-items-center ${!isEmbedded && 'min-vh-100'}`}>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className={`col-lg-4 col-md-6 ${!isEmbedded && ' mt-4 mb-4'}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
          {
            // recaptcha hack. Add extra padding below to ensure that recaptcha popup has enough space
            isEmbedded ? <div style={{paddingBottom:250}}>&nbsp;</div> : null
          }
      </>
    );
});

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import RenderLegalDocLink from '../../../cmp/RenderLegalDocLink';
import LangsSelector from '../../../cmp/LangsSelector';
import MobileAppsFooter from '../../cmp/MobileAppsFooter';

const year = new Date().getFullYear();
const RenderLink = ({to, title}) => !to.includes(':') ? <Link to={to}><FormattedMessage id={title}/></Link> :
    <a rel={"noopener noreferrer nofollow"} href={to} title={title} target={'_blank'}><FormattedMessage id={title}/></a>;

class LandingFooter extends Component {

    render() {
        if(this.props.landing_footer && this.props.landing_footer.visible === false){
            return null;
        }
        const {website} = this.props
        let w = new URL('https://goaffpro.com')
        try {
            w = new URL(website && website.startsWith('http') ? website : `https://${website}`);
        }catch(e){

        }
        const values = {
            link_commissions: <RenderLink to={'/program-details/commissions'} title={'footerCommissions'}/>,
            link_tools: <RenderLink to={'/program-details/tools'} title={'footerTools'}/>,
            link_reporting: <RenderLink to={'/program-details/reports'} title={'footerReporting'}/>,
            link_contact: <RenderLink to={ this.props.contact_page_url ? this.props.contact_page_url : this.props.inDashboard ? '/partner-contact' : '/contact'} title={'contactUs'}/>,
            link_terms: <RenderLegalDocLink type={'terms_url'}/>,
            link_privacy: <RenderLegalDocLink type={'privacy_url'}/>,
            language_picker: <LangsSelector/>,
            year,
            website: <a href={w.href} target={"_blank"}
                        rel={'nofollow noreferrer noopener'}>{w.hostname}</a>
        };
        const blocks = this.props.landing_footer.blocks.map((item) => ({
            ...item,
            description: item.description.split('\n').map((desc) => desc.replace(/\[([^\[\]]+)\]\(([^)]+)\)/g, (match, title, link) => {
                values[title.split(' ').join('')] = <RenderLink to={link} title={title}/>;
                return '{' + title.split(' ').join('') + '}';
            })).join('\n')
        }));
        return (
            <footer className={'bg-light border-top border '}>
                <div className={'container mx-auto pt-4 pt-2 mb-4 text-center text-md-start'}>
                    <div className={'row'}>
                        {
                            blocks.map((item) => <FooterBlock title={item.title} key={item.title} description={item.description} values={values}/>)
                        }
                    </div>
                </div>
                <MobileAppsFooter/>
                {
                    this.props.subscription === 0 &&
                    <small className="text-center d-block mt-2">⚡ by <a href={"https://goaffpro.com/"}
                                                                        target={"_blank"}>Goaffpro</a></small>
                }
                <div className="pb-4"/>
            </footer>
        );
    }
}

const A = ({href, title, children, target}) => {
    if (href.startsWith('https://')) {
        return <a title={title} href={href} target={target}>{children}</a>;
    }
    return <Link title={title} to={href}>{children}</Link>;

};

const FooterBlock = ({title, description, values}) => (<div className={'col-md-3'}>
    <div className={'px-4 mt-2'}>
        {
            title && <div>
                <small className={'font-weight-bold'}>
                    <FormattedMessage id={title} defaultMessage={title}/>
                </small>
            </div>
        }
        {
            description.split('\n').map((item, index) => (<div key={index}>
                {
                    item &&
                    <FormattedMessage id={item} tagName={'small'} defaultMessage={item} values={values}/>
                }
            </div>))
        }
    </div>
</div>);


function mapStateToProps(state) {
    const {page_sections, subscription, contact_page_url} = state.settings;
    const landing_footer = (page_sections.default_footer && page_sections.default_footer.props) || {}
    return {
        website: state.settings.website,
        contact_page_url,
        landing_footer,
        subscription
    }
}

export default connect(mapStateToProps)(LandingFooter);


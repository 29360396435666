import {post} from '../../../api';
import {useEffect, useState} from 'react';
import LoadingSkeleton from '../../cmp/LoadingSkeleton';

export default function RenderTipaltiIframe({type}){
    const [src, setSrc] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading]  = useState(false)
    const [height, setHeight] = useState('800px')
    useEffect(()=>{
        setLoading(true)
        post('/payments/tipalti/get_iframe_url',{
            type
        }).then(({url, error})=>{
            setSrc(url)
            setError(error)
            setLoading(false)
        }).catch((e)=>{
            setLoading(false)
            setError('Network error')
        })
    },[type])
    function tipaltiHandler(evt){
        if (evt.data && evt.data.TipaltiIframeInfo) {
            const {width, height} = evt.data.TipaltiIframeInfo
            if(width) {
                console.log('iframe setting width', width)
            }
            if(height){
                console.log('iframe setting height', height)
                setHeight(height)
            }
        }
    }
    useEffect(()=>{
        window.addEventListener('message',tipaltiHandler, false)
        return ()=>{
            window.removeEventListener('message',tipaltiHandler)
        }
    },[])

    if(loading) {
        return <div className={"text-center my-4"}><LoadingSkeleton height={400} /></div>
    }else if(error){
        return <p className="alert alert-danger mt-2">{error}</p>
    }else{
        return <iframe src={src} scrolling={"no"} style={{width:'100%', overflow:"hidden", border:0, height}} className={"mt-2 border rounded"}/>
    }
}

import React from 'react';

function SocialShareIcon({disabled, onClick, Cmp, alt, src}) {
    return <button disabled={disabled} title={alt} className={"btn btn-link text-secondary px-1 share_" + alt.toLowerCase()} type={"button"}
                   onClick={onClick}>
        {
            Cmp ? Cmp :
        <img alt={alt} role={"button"} tabIndex={0} src={src} width={22} height={22} onClick={onClick}/>
        }
    </button>
}

export default SocialShareIcon;

import React, {useMemo} from 'react';
import {randomString} from '../../../tools/func';

interface CheckedEvent {
    target:{
        checked:boolean
    }
}

export default function Checkbox({checked, value, label, onChange, small, disabled}: {
    checked:boolean,
    value?: string;
    label: string;
    onChange:(inp: CheckedEvent)=>void,
    small?: boolean
    disabled?: boolean
}) {
    const id = useMemo(()=>{
       return randomString()
    },[])
    return (<div className={'mb-2'}>
        <input disabled={disabled} className="form-check-input" onChange={onChange} type="checkbox" id={id}
               value={value}
               checked={checked || false}
        />
        {
            small ? <small>
                    <label className="form-check-label" htmlFor={id}>{label}</label>
                </small> :
                <label className="form-check-label" htmlFor={id}>{label}</label>
        }
    </div>);
}

import {useSelector} from 'react-redux';
import {useMemo} from 'react';

const filtered = ['sponsor','ref','ref_code','coupon','ref_code_and_coupon']

export default function usePendingRequiredFields(){
  const signup_data = useSelector((state)=>state.settings.signup_data);
  const profileFields = useSelector((state)=>state.settings.profileFields);
  const settings = useSelector((state)=>state.settings);

  const isInUSA = useSelector((state)=>state.settings.country === "US")
  const available_payment_methods = useSelector((state)=>state.settings.available_payment_methods || [])

  const form = useMemo(()=>{
    if(!settings) return []
    if(!settings.payment_method) return [];
    const found =  available_payment_methods.find((item)=>item.key===settings.payment_method)
    const vals =  found && found.form ? found.form : [];
    if(settings.payment_method === "bank" && isInUSA){
      // do not display is the user is in USA
      return vals.filter(({id})=>id !== "swift_name")
    }else{
      return vals;
    }
  },[settings?.payment_method])
  const isFormComplete = useMemo(()=>{
    const payment_details_data = typeof settings.payment_details_data === "string" ? JSON.parse(settings.payment_details_data) : null;
    return form.filter(({isRequired, isEnabled})=>isRequired && isEnabled).every(({id})=>payment_details_data && payment_details_data[id])
  },[form])

  return useMemo(()=>{
    const fields = profileFields ? JSON.parse(profileFields) : []
    const data = signup_data ? JSON.parse(signup_data) : {}
    const payment_details_data = typeof settings.payment_details_data === "string" ? JSON.parse(settings.payment_details_data) : null;
    settings.paypal_email = payment_details_data?.paypal_email;
    settings.payment_details_input = settings.payment_method && isFormComplete ? {
      payment_method: settings.payment_method,
      payment_details_data: settings.payment_details_data,
    } : null
    return fields.filter(({id, isEnabled, isRequired})=>isEnabled && isRequired && (settings[id] === undefined || settings[id] === null) && data[id] === undefined && filtered.indexOf(id) < 0)
  },[profileFields, signup_data])
}

import React, {Component, useMemo} from 'react';
import Modal from '../../cmp/Modal';
import MarkdownLoader from '../../pages/landing/MarkdownLoader';
import {connect, useSelector} from 'react-redux';

class LegalDocModal extends Component {
    state = {
        visible: false,
        isMarkdown: true
    }
    openModal = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setState({visible: true})
    }
    closeModal = () => {
        this.setState({visible: false})
    }

    render() {
        let source = undefined
        switch (this.props.to) {
            case "/program-legal/terms":
                source = this.props.terms_url;
                break;
            case '/program-legal/privacy':
                source = this.props.privacy_url;
        }
        const isMarkdown = source.startsWith("/") || source.startsWith("https://static") || source.endsWith('.md')
        if (isMarkdown) {
            return (
                <>
                    <Modal visible={this.state.visible} size={"lg"} onClose={this.closeModal} title={this.props.children}>
                        <LegalDocLoader visible={this.state.visible} to={this.props.to} />
                    </Modal>
                    <a href={this.props.to} target={"_blank"} onClick={this.openModal}>{this.props.children}</a>
                </>
            );
        } else {
            return <a href={source} target={"_blank"}>{this.props.children}</a>

        }
    }
}

export function LegalDocLoader({visible, to}){
    const {
        terms_url, privacy_url
    } = useSelector((state)=>{
        return {
            terms_url: state.settings.terms_url,
            privacy_url: state.settings.privacy_url,
        }
    })
    const source = useMemo(()=>{
        switch (to) {
            case "/program-legal/terms":
                return terms_url;
            case '/program-legal/privacy':
                 return privacy_url;
        }
    },[to, terms_url, privacy_url])
    const isMarkdown = source.startsWith("/") || source.startsWith("https://static") || source.endsWith('.md')

    if(!visible) return null;
    return  (
            isMarkdown ? <MarkdownLoader source={source}/>
                :
                <a href={source} target={"_blank"}>
                    {source}
                </a>
        )
}

function mapStateToProps(state) {
    return {
        terms_url: state.settings.terms_url,
        privacy_url: state.settings.privacy_url,
    }
}

export default connect(mapStateToProps)(LegalDocModal);

import React, {useEffect, useMemo, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import FormRenderer from '../../cmp/formbuilder/FormRenderer';
import {actionMergeSetting} from '../../../redux/settings/settingsAction';
import {post} from '../../../api';
import Modal from '../../cmp/Modal';
import RenderTipaltiIframe from '../tipalti/RenderTipaltiIframe';
import {trimPostData} from '../../../tools/func';
import FormGroup from '../../cmp/form/FormGroup';

function PaymentInputPage({
      updateSettings,
      visible,
      onClose,
      onSubmit,
      available_payment_methods,
      payment_method,
      payment_details_data
}){
    const [saving, setSaving] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(payment_method || '')
    const [paymentDetails, setPaymentDetails] = useState(payment_details_data)
    useEffect(()=>{
        setPaymentMethod(payment_method || '')
        setPaymentDetails(payment_details_data)
    },[payment_method, payment_details_data])
    useEffect(()=>{
        if(available_payment_methods && available_payment_methods.length === 1){
            setPaymentMethod(available_payment_methods[0].key)
        }
    },[available_payment_methods])
    const save = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if(onSubmit){
            if(paymentMethod) {
                onSubmit({
                    payment_method: paymentMethod,
                    payment_details_data: trimPostData(paymentDetails)
                })
            }
            onClose();
            return;
        }
        setSaving(true)
        post('/', {
            payment_method: paymentMethod,
            payment_details_data: JSON.stringify(trimPostData(paymentDetails))
        }).then((data) => {
            updateSettings(data);
            setSaving(false)
            onClose();
        }).catch((e) => {
            setSaving(false)
        });
    }

    const shouldEnable = (form)=>{
        const values = paymentDetails;
        for (let x = 0; x < form.length; x++) {
            const {id, isRequired, isEnabled} = form[x];
            if (isEnabled && isRequired){
                if(typeof values[id] === "string"){
                    if(!values[id].trim()){
                        return false;
                    }
                }else if(!values[id] || values[id].length === 0) {
                    return false;
                }
            }
        }
        return true;
    }
    const onChange = (data)=> {
        setPaymentDetails((d)=>{return {...d, ...data}})
    }
    const isInUSA = useSelector((state)=>state.settings.country === "US"|| state.settings.country === "CA")
    const form = useMemo(()=>{
        const found =  available_payment_methods.find((item)=>item.key===paymentMethod)
        const vals =  found && found.form ? found.form : [];
        if(paymentMethod === "bank" && isInUSA){
            // do not display is the user is in USA
            return vals.filter(({id})=>id !== "swift_name")
        }else{
            return vals;
        }
    },[available_payment_methods, paymentMethod, isInUSA])

    return (
            <Modal
                size={paymentMethod === "tipalti" ? 'lg' : null}
                onCancel={onClose}
                visible={visible}
                disabled={!shouldEnable(form)}
                onSubmit={save} confirmLoading={saving}
                okText={<FormattedMessage id={"Submit"} />}>
                <FormGroup label={'Payment Mode'} required>
                    <select className={'form-control'} value={paymentMethod}
                            onChange={(e)=>setPaymentMethod(e.target.value)}>
                        <Opt value={''} id={'No payment mode set'}/>
                        {
                            available_payment_methods.map((item)=>{
                                return <Opt key={item.key} id={item.title} value={item.key} />
                            })
                        }
                    </select>
                </FormGroup>
                {
                    paymentMethod === "tipalti" ? <RenderTipaltiIframe type={"payment_details"} /> : null
                }
                <FormRenderer formFields={form}
                              onChange={onChange}
                              value={paymentDetails} />
            </Modal>
        );
}

function mapStateToProps(state, props) {
    const {available_payment_methods} = state.settings;
     const payment_method = props.payment_method ?? state.settings.payment_method;
     const payment_details_data = props.payment_details_data ?? state.settings.payment_details_data ?? {};
    return {
        available_payment_methods,
        payment_method,
        payment_details_data: typeof payment_details_data === "string" ? JSON.parse(payment_details_data) : payment_details_data,
    };
}

function Opt({id, value}) {
    return <FormattedMessage id={id || " "}>{text => <option id={createId(id)} value={value}>{text}</option>}</FormattedMessage>
}

function mapDispatchToProps(dispatch, props) {
    return {
        updateSettings: (change) => dispatch(actionMergeSetting(change))
    };
}

function createId(id){
    return id ? String(id).replace(/[^a-z0-9]/gi, '') : ''
}



export default connect(
    mapStateToProps, mapDispatchToProps
)(PaymentInputPage);

import LoadingSkeleton from '../components/cmp/LoadingSkeleton';
import React from 'react';

const Page = React.lazy(()=>import('./CompleteProfileBeforeAccess'))

export default function LazyCompleteProfileBeforeAccess(props){
  return <React.Suspense fallback={<LoadingSkeleton height={36.5} count={12} />}>
    <Page {...props} />
  </React.Suspense>
}

import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import RenderFormInput from './RenderFormInput';

function FormRenderer ({value, formFields, onChange}){
    const onFormInput = useCallback((key)=> {
      return (e)=>{
        onChange({...value, [key]:e.target.value})
      }
    },[value, onChange]);

    const filteredFields = useMemo(()=>{
      return formFields ? formFields.filter(({isEnabled})=>isEnabled) : []
    },[formFields])
    return (
        <>
            <div className={'row flex-wrap'}>
                {
                  filteredFields
                        .map((item, index) =>
                            (<RenderFormInput key={item.id + "_" + index} {...item}
                                        value={value[item.id] || ""}
                                        isRequired={item.isRequired}
                                        onChange={onFormInput(item.id)}
                            />))
                }
            </div>
        </>
    );
}

FormRenderer.propTypes = {
    formFields: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
}


export default FormRenderer

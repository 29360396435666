import React, {lazy} from 'react';

const Component = lazy(() => import('./SimpleFileUpload_Internal'));

function SimpleFileUpload(props) {
    return (
        <React.Suspense fallback={<div style={{height:38}}></div>}>
            <Component {...props} />
        </React.Suspense>
    );
}

export default SimpleFileUpload;

import React from 'react';
import PropTypes from 'prop-types';

const DropdownInternal = React.lazy(() => import('./Dropdown_Internal'));
function Dropdown(props) {
        return (
            <React.Suspense>
              <DropdownInternal {...props} />
            </React.Suspense>

        )
}

Dropdown.propTypes = {
    opts: PropTypes.array.isRequired,
    selected: PropTypes.any.isRequired,
    onSelected: PropTypes.func.isRequired,
}

export default Dropdown;

import React from 'react';
import Modal from '../Modal';
import LazyRenderQRCode from './LazyRenderQRCode';
import {FormattedMessage} from 'react-intl';
import {apiServer} from '../../../api';

function QrCodeModal({visible, onClose, value}) {
  return (
      <Modal visible={visible} title={"QR Code"} onClose={onClose}>
        <div className={"p-4 text-center"}>
          <LazyRenderQRCode value={value} size={240} level={'H'}/>
        </div>
        <div className="text-center">
          <a href={`${apiServer}/extras/qr?data=${encodeURIComponent(value)}`}>
            <FormattedMessage id={"Download"}/>
          </a>
        </div>
      </Modal>
  );
}


export default QrCodeModal;

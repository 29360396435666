import React, {Component} from 'react';
import StyledLink from '../../../cmp/themed/StyledLink';
import {FormattedMessage} from 'react-intl';
import DefaultCommissionRender from './DefaultCommissionRender';
import {connect} from 'react-redux';

class JoinNowBottomButton extends Component {
    render() {
        const {hero_btntext} = this.props;
        return (
            <div className={'text-center py-4 bg-white'}>
                {
                    hero_btntext &&
                    <StyledLink to={'/create-account'} className={'btn btn-primary'}>
                        <FormattedMessage id={hero_btntext || 'joinNow'} values={{
                            commission_value: <DefaultCommissionRender/>
                        }}/>
                    </StyledLink>
                }
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        hero_btntext: props.bottom_button_text !== undefined ? props.bottom_button_text : 'joinNow',
    }
}


export default connect(mapStateToProps)(JoinNowBottomButton);

import React, {useEffect, useMemo, useState} from 'react';
import {post} from '../../api';
import StyledButton from '../cmp/themed/StyledButton';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import {connect, useSelector} from 'react-redux';
import Spinner from '../cmp/Spinner';
import RecaptchaRenderer from '../cmp/RecaptchaRenderer';
import FormGroup from '../cmp/form/FormGroup';
import useObjectState from '../cmp/hooks/useObjectState';
import Input from '../cmp/form/Input';
import Select from '../cmp/form/Select';
import {useNavigate} from 'react-router-dom';

const emailValidator = new RegExp(/\S+@\S+\.\S+/);

function ContactUs({contact_page_url, recaptcha_enabled,affiliate_id}){
    const zendesk_contact_enabled = useSelector((state)=>state.settings.zendesk_contact_enabled)
    const navigate = useNavigate()
    const [data, setData] = useObjectState({
        name:'',
        email: '',
        recaptcha_response:'',
        queryType:'General'
    })
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError ] = useState(null)

    useEffect(()=>{
       if(contact_page_url){
           window.location = contact_page_url;
       }else if(zendesk_contact_enabled){
           navigate('/sso/zendesk')
       }
    },[contact_page_url, zendesk_contact_enabled])


    const onSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const {queryType, recaptcha_response, message, name, email} = data
        if (!submitEnabled) return;
        if (loading) return;
        setLoading(true)
        setSuccess(false)
        setError(null)
        post('/partner-contact', {
            message, queryType, name, email,
            recaptcha_response,
        }).then((data) => {
            setLoading(false)
            if (data.success) {
                setSuccess(true)
                setData({
                  name:'',
                  email:'',
                  message:'',
                })
            }
            if (data.error) {
                setError(data.error)
            }
        }).catch(() => {
            setLoading(false)
        });
    }


    const submitEnabled = useMemo(() => {
        const {recaptcha_response, name, email, message} = data
        if (affiliate_id) {
            return message && message.length > 0;
        }
        return (!recaptcha_enabled  ? true : recaptcha_response) && name && email && message && message.length > 0 && emailValidator.test(email);
    },[data, affiliate_id])

        if (contact_page_url) {
            return <div className="container d-flex align-items-center justify-content-center" style={{minHeight: 400}}>
                <Spinner size={'lg'}/>
            </div>;
        }
        return (
            <div className={'mt-4 d-flex justify-content-center'}>
                <FormattedHelmet id={'contactUs'}/>
                <div className={'col-md-6'}>
                    <h1 className={'text-center'}>
                        <FormattedMessage id={'contactUs'}/>
                    </h1>
                    <div className={'card'}>
                        <form className={'card-body'} onSubmit={onSubmit}>
                            <FormattedMessage id={'pageDesc'} tagName={'p'}/> {
                            success ?
                                <p className={'alert alert-success'}>
                                    <FormattedMessage id={'messageSentSuccess'}/>
                                </p> : null
                        }
                            {
                                error && <p className="alert alert-danger">{error}</p>
                            }
                            {
                                !affiliate_id && <div>
                                    <FormGroup label={'yourName'} required>
                                        <Input type="text" name={'name'} value={data.name} autoComplete={'name'}
                                               onChange={setData('name')}
                                        />
                                    </FormGroup>
                                    <FormGroup label={'emailAddress'} required>
                                        <Input type="text" name={'email'} value={data.email} autoComplete={'email'}
                                               onChange={setData('email')} className="form-control"
                                        />
                                    </FormGroup>
                                </div>
                            }
                            <FormGroup label={'issueTypeLabel'}>
                                <Select value={data.queryType}
                                        options={[
                                            {
                                                label:'issueTypeGeneral',
                                                value:'General'
                                            },
                                            {
                                                label:'issueTypeTechnical',
                                                value:'Technical'
                                            },
                                            {
                                                label:'issueTypeFeedback',
                                                value:'Feedback'
                                            },
                                            {
                                                label:'issueTypePayment',
                                                value:'Payment'
                                            },
                                            {
                                                label:'issueTypeDelAcct',
                                                value:'Account Deletion Request'
                                            }

                                        ]}
                                        onChange={setData('queryType')}>
                                </Select>
                            </FormGroup>
                            <FormGroup label={'yourMessage'}>
                                <textarea className={'form-control'} value={data.message} onChange={(e)=>setData('message')(e.target.value)}/>
                            </FormGroup>
                            {
                                !affiliate_id ? <RecaptchaRenderer onChange={(e)=>setData('recaptcha_response')(e.target.value)} /> : null
                            }
                            <div className="d-flex justify-content-between">
                                        <StyledButton
                                            loading={loading}
                                            className={'btn btn-primary'}
                                            onClick={onSubmit}
                                            disabled={!submitEnabled}
                                        >
                                            <FormattedMessage id={'sendMessage'}/>
                                        </StyledButton>
                                <small className={'text-danger'}>* - <FormattedMessage id={'required'}/></small>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
}


function mapStateToProps(state) {
    return {
        affiliate_id: state.settings.affiliate_id,
        recaptcha_enabled: state.settings.recaptcha_enabled,
        contact_page_url: state.settings.contact_page_url
    };
}

export default connect(mapStateToProps)(ContactUs);

import React from 'react';

function Spinner({size, width, height}:{
    size?:'lg' | 'sm'
    width?: number;
    height?: number;
}){
    return <span
        style={{width, height}}
        className={`spinner-border spinner-border-${size || 'sm'} mr-1`} role="status"
                 aria-hidden="true"></span>
}
export default Spinner



export default function commissionsReducer(state = {}, action){
    switch (action.type){
        case "set-commissions":
            return action.commissions || state;
        default:
            return state
    }
}

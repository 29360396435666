import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import DefaultCommissionRender from './DefaultCommissionRender';

class LandingThreeCard extends Component {
    render() {
        if (!this.props.landing_page_card.visible) {
            return null;
        }
        const {default_commission_value} = this.props;
        const values = {
            join_now: <Link to={'/create-account'}><FormattedMessage id={'joinNow'}/></Link>,
            link_tools: <Link to={'/program-details/tools'}><FormattedMessage id={'linkingTools'}/></Link>,
            link_commissions: <Link to={'/program-details/commissions'}><FormattedMessage
                id={'productCommissions'}/></Link>,
            commission_value: default_commission_value > 0 ? <DefaultCommissionRender/> : ''
        };
        return (
            <div className={'bg-white pt-4 landing-page-card-group'}>
                <div className={'container mx-auto'}>
                    <h2 className={'text-center'}>
                        {
                            this.props.landing_page_card.card_title &&
                            <FormattedMessage id={this.props.landing_page_card.card_title}
                                              defaultMessage={this.props.landing_page_card.card_title}/>
                        }
                    </h2>
                    <div className={'row pt-2 justify-content-between'}>
                        <div className={'col'}>
                            <div className={'card-group'}>
                                {
                                    this.props.landing_page_card.cards.map(({title, img, description}) => {
                                        if (!title && !img && !description) return null;
                                        return (<CardContainer key={title}>
                                            {
                                                img &&
                                                <img alt={title} title={title} src={img} width={64} className={'mb-2'}/>
                                            }
                                            {
                                                title &&
                                                <h3><FormattedMessage id={title} defaultMessage={title}/></h3>
                                            }
                                            {
                                                ['sectionCardFirstDetail', 'sectionCardSecondDetail', 'sectionCardThirdCommDetail'].indexOf(description) > -1 ?
                                                    <p style={{whiteSpace: 'pre-line'}}>
                                                        <FormattedMessage id={description} values={values}
                                                                          defaultMessage={description}/>
                                                    </p>
                                                    : <div dangerouslySetInnerHTML={{__html: description}}/>
                                            }

                                        </CardContainer>);
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function CardContainer({children}) {
    return (<div className={'card'}>
        <div className={'card-body text-center'}>
            {children}
        </div>
    </div>);
}


function mapStateToProps(state) {
    const {page_sections} = state.settings;
    return {
        default_commission_value: state.settings.default_commission_value,
        landing_page_card: (page_sections.default_cards && page_sections.default_cards.props) || {}
    };
}

export default connect(
    mapStateToProps
)(LandingThreeCard);

import {useEffect} from 'react';
import {getCurrentQueryObject} from '../../tools/func';
import {useParams} from 'react-router-dom';
import {setAccessToken} from '../../api';

export default function AutoLogin() {
    const params = useParams()
    useEffect(() => {
        const {to} = getCurrentQueryObject();
        if (params.access_token) {
            setAccessToken(params.access_token, true)
            window.location = to || "/"
        }
    }, [params.access_token])
    return null;
}

import React from 'react';

const ModalInternal = React.lazy(() => import('./Modal_Internal'));
function Modal(props) {
    return (
       <React.Suspense>
           <ModalInternal {...props} />
       </React.Suspense>
    );
}
export default Modal;

import {Link} from 'react-router-dom';
import {css} from 'emotion';
import {getTextColor, pSBC} from '../../../styles/colorutils';
import myemotionhoc from './myemotionhoc';

const mstyle = ({themeprimarycolor, themebuttontextcolor}) => css`
	background-color: ${themeprimarycolor || '#2780E3'} !important;
	color: ${themebuttontextcolor || getTextColor(themeprimarycolor) || 'white'} !important;
	border-color: ${pSBC(-0.2, themeprimarycolor || '#2780E3')} !important;
	&:hover{
		background-color: ${pSBC(-0.5, themeprimarycolor || '#2780E3')} !important;		
	}
	`;

export default myemotionhoc(Link, mstyle);

import PropTypes from 'prop-types';
import LoadingSkeleton from '../LoadingSkeleton';
import React from 'react';

const Page = React.lazy(()=>import('./MultipleSelector'))

export default function LazyMultipleSelector(props){
    return <React.Suspense fallback={<LoadingSkeleton height={36.5} />}>
        <Page {...props} />
    </React.Suspense>
}


LazyMultipleSelector.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any.isRequired,
    onSelected: PropTypes.func.isRequired

};

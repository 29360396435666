import React from 'react';
export  {
    FaGlobe as  WebsiteIcon,
    FaTwitter as TwitterIcon,
    FaInstagram as InstagramIcon,
    FaFacebook as FacebookIcon,
    FaYoutube as YoutubeIcon,
    FaSnapchat as SnapchatIcon,
    FaPinterest as PinterestIcon,
    FaCheckCircle as CheckIcon,
    FaAsterisk as AsteriskIcon,
    FaPencilAlt as PencilIcon,
    FaExternalLinkAlt as ExternalLinkIcon,
    FaCheck as TickIcon,
    FaChevronRight as NextIcon,
    FaChevronLeft as PreviousIcon,
    // FaEye as EyeOpen,
    // FaEyeSlash as EyeClose,
    FaPlus as PlusIcon,
    FaFilePdf as PdfDownloadIcon,
    FaQuestionCircle as HelpIcon,
    FaHeart as HeartIconFill,
    FaRegHeart as HeartIconOutline,
    FaRegFileExcel as ExcelIcon,
} from 'react-icons/fa';
export {
    IoIosClose as XIcon,
    IoIosMore as ThreeDotsHorizontalIcon,
    IoIosCart as CartIcon,
} from 'react-icons/io'
export  {
    MdArrowForward as ArrowRight,
    MdArrowBack as ArrowLeft,
    MdArrowDropDown as SortDownIcon,
    MdArrowDropUp as SortUpIcon,
    MdKeyboardBackspace as BackButtonIcon,
    MdInbox as EmptyBoxIcon,
    MdInput as InputIcon,
    MdExpandMore as MdExpandMore,

}from 'react-icons/md';
export const TikTokIcon = (props = {})=> <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
</svg>


export {GrPhone as PhoneIcon} from 'react-icons/gr'


export {FiTrash as TrashIcon,
FiEye as EyeOpen,
    FiEyeOff as EyeClose
} from 'react-icons/fi'

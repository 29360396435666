import React from 'react';

const StyledHeader = React.lazy(() => import('./StyledHeaderSvg_Internal'));

function StyledHeaderSvg({height}) {
    const width = (893.61704 / 736.75) * height;
    return (
        <span style={{height, width}}>
            <React.Suspense>
                <StyledHeader height={height} width={width}/>
            </React.Suspense>
        </span>
    );
}

export default StyledHeaderSvg;

import {post} from '../../api';

export const actionMergeSetting = (settings) => ({
	type: 'merge-settings',
	settings
});


export const updateSetting = (key, value) => (dispatch, getState) => {
	const originalValue = getState().settings[key];
	dispatch(actionMergeSetting({ saving: true }));
	dispatch(actionMergeSetting({ [key]: value }));
	return post('/sites/settings',{
		setting_key: key, setting_value: value
	}).then(({ data }) => {
		const { setting_key, setting_value } = data;
		dispatch(actionMergeSetting({ [setting_key]: setting_value, saving: false }));
		return data;
	}).catch((e) => {
		dispatch(actionMergeSetting({ [key]: originalValue, saving: false }));
	})
};


/**
 * Temporary function to build landing page as per the new design
 */
export function buildPageSections(settings){
	const { page_sections, landing_page_card, default_commission_value, hero_banner, hero_supertext, hero_subtext, hero_text, hero_btntext, hero_align, hero_textcolor } = settings;
	const show_commission = default_commission_value > 0;
	const landing_footer = settings.landing_footer ? JSON.parse(settings.landing_footer) : undefined;

	return {
		"default_navigation": {
			key:'default_navigation',
			component:'TopNavBar',
			props:{
				visible:true,
				navbar_bg:settings.navbar_bg || '#f8f9fa',
				logo_align:settings.logo_align || 'left',
			}
		},
		"default_banner":{
			key:'default_banner',
			component:'HeroComponent',
			props:{
				visible:true,
				hero_banner,
				hero_supertext:hero_supertext !== undefined ? hero_supertext : 'heroline',
				hero_subtext: hero_subtext !== undefined ? hero_subtext : 'herosubtext',
				hero_text: hero_text !== undefined ? hero_text : show_commission ? 'heroDescHasCommission':'heroDescNoCommission',
				hero_btntext: hero_btntext !== undefined ? hero_btntext : 'joinNow',
				hero_textcolor:hero_textcolor || '#212121',
				hero_align: hero_align || 'left',
			}
		},
		"default_cards":{
			key:'default_cards',
			component:'LandingThreeCard',
			props:{
				visible: landing_page_card ? landing_page_card.show_section : true,
				card_title: landing_page_card ? landing_page_card.card_title : 'sectionHeadingHowItWorks',
				cards: landing_page_card ? landing_page_card.cards :  [
					{
						title: 'sectionCardFirstTitle',
						img: '/assets/003-open.svg',
						description: 'sectionCardFirstDetail'
					},
					{
						title: 'sectionCardSecondTitle',
						img: '/assets/002-megaphone.svg',
						description: 'sectionCardSecondDetail'
					},
					{
						title: 'sectionCardThirdTitle',
						img: '/assets/001-change.svg',
						description: 'sectionCardThirdCommDetail'
					}
				]
			}
		},
		"default_bottom_button":{
			key:'default_bottom_button',
			component:'JoinNowBottomButton',
			props:{
				visible:true,
				bottom_button_text: hero_btntext || 'joinNow',
			}
		},
		"default_footer":{
			key:'default_footer',
			component:'LandingFooter',
			props:{
				visible:true,
				blocks: landing_footer ?
					landing_footer.blocks :
					[
						{
							title: 'footerLearnMore',
							description: '{link_commissions}\n{link_tools}\n{link_reporting}'
						},
						{
							title: 'footerCustomerSupport',
							description: '{link_contact}'
						},{
							title: 'footerLegal',
							description: '{link_terms}\n{link_privacy}'
						},
						{
							title: '',
							description: '©{year}, {website}\n{language_picker}'
						}]

			}
		},
		...page_sections
	}
}

import React from 'react';
import {connect} from 'react-redux';
import {buildRefLink} from '../../../../tools/functions';
import InnerHTML from 'dangerously-set-html-content';
import mustache from 'mustache';

function HTMLComponent({html}){
    return html ? <InnerHTML html={html}/> : null
}

const reg2 = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/gm;

function mapStateToProps(state, props) {
    const {coupon, name, email, ref_code, fname, lname, instagram, twitter, facebook, my_coupons, website, partner_portal_subdomain, ref_identifiers, referral_link_params} = state.settings;
    const personal_coupon = my_coupons ? my_coupons.find(({type})=>type === "personal") : null
    let ident = 'ref';
    try{
        ident = typeof ref_identifiers === 'string' ? JSON.parse(ref_identifiers) : ref_identifiers;
    }catch(e){
    }
    const object = {
        name, email,
        ref_link:  buildRefLink(ref_code, website, ref_identifiers, referral_link_params, state.settings),
        ref_code,
        coupon,
        first_name: fname,
        last_name: lname,
        personal_coupon,
        instagram,
        facebook,
        twitter,
    }
    let html = mustache.render(props.html || '', object);
    html = html.replace(/\{coupon\}/g, coupon);
    if(personal_coupon) {
        html = html.replace(/\{personal_coupon\}/g, personal_coupon.code)
    }
    html = html.replace(/\{name\}/g, name);
    html = html.replace(/\{email\}/g, email);
    html = html.replace(/\{ref_code\}/g, ref_code);
    html = html.replace(reg2, (x) => {
        try {
            const url = new URL(x);
            if (url) {
                //there is problem with embedded facebook videos, so only append ref if the hostname contains website.
                if (website.includes(url.hostname)) {
                    const primary_identifier = ident && ident.length > 0 ? ident[0] : undefined;
                    if(primary_identifier){
                        let exists = primary_identifier === "hash" ? url.hash : url.searchParams.get(primary_identifier);
                        if(!exists){
                            return buildRefLink(ref_code, url.href, ref_identifiers, referral_link_params, state.settings)
                        }
                    }
                }
                return url.href;
            }
        } catch (e) {

        }
        return x;
    });
    html += `<script type="text/javascript">
    document.querySelectorAll('a').forEach((a)=>{
        a.addEventListener('click', (e)=>{
            const url = new URL(e.target.href);
            if(url.hostname === window.location.hostname){
                 e.preventDefault();
                 e.stopPropagation();
                 window.dispatchEvent(new CustomEvent('goaffpro:navigate', {detail: {url: url.pathname}}))
           }
        })
    })
    </script>`;
    return {
        html,
    };
}

export default connect(mapStateToProps)(HTMLComponent);

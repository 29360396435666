const jsonObjectKeys = ['automatic_personal_coupons','custom_payment_methods']

export default function settingsStateToProps(keys){
    return (state, props)=>{
        if(keys) {
            const map = {
                subscription: state.settings.subscription
            }
            keys.forEach((item) => {
                if (jsonObjectKeys.indexOf(item) > -1) {
                    map[item] = typeof state.settings[item] === "string" ? JSON.parse(state.settings[item]) : state.settings[item]
                } else {
                    map[item] = state.settings[item]
                }
            })
            return map
        }
        return state.settings;
    }
}

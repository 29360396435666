import React from 'react';

function SimpleDateInput({placeholder, id, min, max, name, value, onChange}) {
    return (<input placeholder={placeholder} type={'date'}
                   min={min}
                   max={max}
                   id={id} name={name}
                defaultValue={value} onChange={onChange}
                className={'form-control'}
        />);
}

export default SimpleDateInput;

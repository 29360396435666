import React from 'react';
import {FormattedMessage} from 'react-intl';

export default function ({children, required, title, htmlFor}:{
    children?: React.ReactNode,
    required?: boolean,
    title?: string
    htmlFor?: string
}) {
    return <label htmlFor={htmlFor} className={'text-muted mb-0'}>{title && <FormattedMessage id={title}/>}{children} {required &&
    <span className={"text-danger"}>*</span>}</label>;
}

import React from 'react';
import LoadingSkeleton from '../LoadingSkeleton';

const Cmp = React.lazy(()=>import('./SignatureInputInternal'))

function SignatureInput(props) {
  return <React.Suspense fallback={<div style={{width:350}}><LoadingSkeleton height={200} /></div>}>
    <Cmp {...props} />
  </React.Suspense>
}

export default SignatureInput;

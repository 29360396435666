import React from 'react';
import LazyPageLoader from '../../cmp/LazyPageLoader';

const Page = React.lazy(()=>import('./MarkdownLoaderInternal'))

export default function MarkdownLoader({
                                           source, hidePdf, customObject,
    formFields,
                                 }){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page source={source} customObject={customObject} hidePdf={hidePdf} formFields={formFields} />
    </React.Suspense>
}
MarkdownLoader.propTypes = {

}

import React, {useEffect, useState} from 'react';
import AuthContainer from './components/AuthContainer';
import Label from './components/Label';
import {post, setAccessToken} from '../../api';
import LogoRender from '../cmp/LogoRender';
import StyledButton from '../cmp/themed/StyledButton';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import FormInput from './components/FormInput';
import RenderError from './components/RenderError';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {emailValidator} from './ForgotPassword';
import PasswordInput from '../cmp/form/PasswordInput';
import Input from '../cmp/form/Input';
import usePasswordRequirements from '../../hooks/usePasswordRequirements';

function ResetPassword() {
    const [email, setEmail] = useState('');
    const params = useParams();
    const [resetkey, setResetKey] = useState(null)
    useEffect(()=>{
        if(params) {
            setResetKey(params.resetkey)
        }
    },[params])
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [errorCode, setErrorCode] = useState(null)
    const navigate = useNavigate()
    const requirements = usePasswordRequirements()
    const doReset = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setLoading(true)
        setError(null)
        setErrorCode(null)
        post('/resetpassword', {
            email, password, resetkey
        }).then((data) => {
            if (data.access_token) {
                setAccessToken(data.access_token);
                document.location = "/"
                // navigate("/",{
                //     replace:true
                // })
            } else {
                setError(data.error || 'Unexpected error')
                setErrorCode(data.code)
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        });
    }
         return (
            <AuthContainer>
                <FormattedHelmet id={'resetPassword'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <h1 className={"text-center"}><FormattedMessage id={'resetPassword'}/></h1>
                <div className={'card shadow-sm'}>
                    <div className={'card-body'}>
                        <form onSubmit={doReset}>
                            {
                                errorCode && <RenderError code={errorCode}/>
                            }
                            {
                                !params.resetkey ?
                                    <div className={'form-group'}>
                                        <Label>Password Reset Code</Label>
                                        <input className={'form-control'} type={'code'}
                                               onChange={(e)=>setResetKey(e.target.value)} value={resetkey}
                                        />
                                    </div> : null
                            }

                            <FormInput id={'emailAddress'}>
                                <Input autoComplete={'email'} name={'email'} type={'email'}
                                       onChange={setEmail} value={email}/>
                            </FormInput>
                            <FormInput id={'newPassword'}>
                                <PasswordInput
                                        requirements={requirements}
                                       autoComplete={'new-password'}
                                       onChange={(e)=>setPassword(e.target.value)}
                                       value={password}
                                />
                            </FormInput>
                            <div className={'form-group'}>
                                <StyledButton loading={loading} disabled={!resetkey || !email || !password || !emailValidator.test(email)} className={'btn btn-primary'}>
                                    <FormattedMessage id={'resetPassword'}/>
                                </StyledButton>
                            </div>
                        </form>
                    </div>
                </div>
                {
                    errorCode &&
                    <div className="mt-2 text-center">
                        <Link to={"/forgot-password"}><FormattedMessage id={"forgotPassword"}/></Link>
                    </div>
                }
            </AuthContainer>
        );
}

export default ResetPassword;

import React, {Component} from 'react';
import {connect} from 'react-redux';
import LogoRender from '../../../cmp/LogoRender';
import StyledLink from '../../../cmp/themed/StyledLink';
import {FormattedMessage} from 'react-intl';

class TopNavBar extends Component {
    render() {
        const {logo_align, navbar_bg, login_visibility, logo_height, isVisible} = this.props;
        if(!isVisible){
            return null
        }
        let mobileLogo = null;
        if (logo_align === "center") {
            mobileLogo = <div className="d-block d-sm-none" style={{width: '100%'}}>
                <div className="text-right pt-1">
                    <StyledLink to={'/login'} className={'btn btn-primary'}>
                        <FormattedMessage id={'Login'}/>
                    </StyledLink>
                </div>
                <span className={'d-block px-2 py-1 text-center'}>
						<LogoRender linked logo_height={Math.min(Number(logo_height), 120)} showDefault/>
					</span>
            </div>
        } else {
            mobileLogo = <div
                className={`d-sm-none d-flex justify-content-between align-items-center ${logo_align === "left" && 'flex-row-reverse'}`}
                style={{width: '100%'}}>
                <div className="text-right pt-1">
                    <StyledLink to={'/login'} className={'btn btn-primary'}>
                        <FormattedMessage id={'Login'}/>
                    </StyledLink>
                </div>
                <span className={'d-block px-3 py-1 text-center'}>
						<LogoRender linked logo_height={Math.min(Number(logo_height), 120)} showDefault/>
					</span>
            </div>
        }
        return (
            <nav className="navbar navbar-light" style={{backgroundColor: navbar_bg}}>
                <div style={{width: '100%', textAlign: logo_align,    minHeight:48,}} className="d-none py-1 d-sm-block px-3">
                    <LogoRender className="navbar-brand mr-0" linked/>
                    <ul className="navbar-nav text-right" style={{
                        position: 'absolute',
                        float: 'right',
                        top: 10,
                        right: 24,
                        visibility: login_visibility
                    }}>
                        <li className="nav-item">
                            <StyledLink to={'/login'} className={'btn btn-primary'}>
                                <FormattedMessage id={'Login'}/>
                            </StyledLink>
                        </li>
                    </ul>
                </div>

                {mobileLogo}
            </nav>
        );
    }
}

function mapStateToProps(state, props) {
    const {page_sections} = state.settings;
    const {logo_align, navbar_bg, visible} = (page_sections.default_navigation && page_sections.default_navigation.props) || {};
    return {
        isVisible: visible,
        store_logo: state.settings.store_logo || 'https://goaffpro.com/static/images/logo.png',
        logo_height: state.settings.logo_height || 80,
        logo_align: logo_align,
        navbar_bg: navbar_bg,
        login_visibility: 'visible',
    };
}

export default connect(
    mapStateToProps
)(TopNavBar);

import {useSelector} from 'react-redux';
import {useMemo} from 'react';

export default function usePasswordRequirements(){
  const signupFields = useSelector((state) => state.settings.signupFields);
  return useMemo(()=>{
    try{
      return JSON.parse(signupFields).find(({id})=>id === 'password').requirements;
    }catch(e){

    }
    return null;
  },[signupFields])
}

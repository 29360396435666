import React, {useState} from 'react';
import {post, setAccessToken} from '../../api';
import FormGroup from './form/FormGroup';
import Input from './form/Input';
import Button from './form/Button'
import AuthContainer from '../auth/components/AuthContainer';
import FormattedHelmet from './intl/FormattedHelmet';
import LogoRender from './LogoRender';
import {FormattedMessage} from 'react-intl';
import RenderError from '../auth/components/RenderError';
import StyledButton from './themed/StyledButton';
import {Link} from 'react-router-dom';
export default function TotpInput(){
  const [token, setToken] = useState();
  const [error, setError] = useState(null);
  const [validating, setValidating] = useState(false);

  function onSubmit(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setError(null);
    setValidating(true);
    post("/../v1/user/totp/login", {
      token,
    })
    .then((data) => {
      setValidating(false);
      if (data.isValid) {
        setAccessToken(data.access_token)
        window.location.reload();
      } else {
        setError("Invalid TOTP");
      }
    }).catch((e)=>{
      setValidating(false)
      console.log(e)
    });
  }
  const [code, setCode] = useState('')

  return (
      <AuthContainer>
        <FormattedHelmet id={'Login'}/>
        <div className={'text-center mb-4'}>
          <LogoRender/>
        </div>
        <h1 className={"text_on_bg text-center"}><FormattedMessage id={'OTP Code'}/></h1>
        <div className={'card shadow-sm'}>
          <div className={'card-body'}>
            {
                code && <RenderError code={code}/>
            }

      <form onSubmit={onSubmit}>
        <FormGroup label={"OTP"} info={"Enter the OTP code from your Authenticator app"}>
          <Input
              type="number"
              value={token}
              onChange={setToken}
          />
        </FormGroup>
        {error && <p className="text-danger">{error}</p>}
          <StyledButton
              title={"Submit"}
              loading={validating}
              onClick={onSubmit}
              className={"btn btn-primary"}
          />
      </form>
          </div>
        </div>
        <div className="text-center mt-2">
          <Link to={"/logout"}>Logout</Link>
        </div>
      </AuthContainer>
  );
}

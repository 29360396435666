import {useEffect} from 'react';
import {get} from '../../../api';
import {useNavigate} from 'react-router-dom';
import Spinner from '../../cmp/Spinner';

function ZendeskRedirect(props) {
  const navigate = useNavigate()
  useEffect(()=>{
    get('/extras/integrations/zendesk')
        .then((data)=>{
          if(data.payload) {
            const form = document.createElement('form')
            form.method = "POST"
            form.action = `https://${data.subdomain}.zendesk.com/access/jwt`;
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = 'jwt'
            input.value = data.payload
            form.appendChild(input)
            document.body.appendChild(form)
            form.submit()
          }else{
            navigate('/login?next=/sso/zendesk')
          }
    }).catch((e)=>{
      navigate('/login?next=/sso/zendesk')
    })
  },[])
  return <div className="my-4 text-center">
    <Spinner size={"lg"} />
  </div>;
}

export default ZendeskRedirect;

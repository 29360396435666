import React, {useEffect, useMemo, useState} from 'react';
import {post, setAccessToken} from '../../../api';
import AuthContainer from './AuthContainer';
import RenderError from './RenderError';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import Spinner from '../../cmp/Spinner';

function getCurrentQueryObject(search) {
    const map = {};
    const s = search || document.location.search;
    s.substr(1).split('&').map((item) => item.split('=')).forEach((x) => {
        map[x[0]] = decodeURIComponent(x[1]);
    });
    return map;
}

function OAuthCallback({gtag_signup_conversion_id, snapchat_pixel_id, fb_signup_conversion_event}) {
    const [errorCode, setErrorCode] = useState(null)
    const params = useParams();

    const endpoint = useMemo(()=>{
        switch (params.type) {
            case 'facebook':
                return '/facebookauth';
            case 'google':
                return '/googleauth';
        }
    },[params.type])
    useEffect(()=>{
        const {access_token} = getCurrentQueryObject(document.location.hash || document.location.search);
        if (access_token && endpoint) {
            if (gtag_signup_conversion_id) {
                try {
                    window.gtag('event', 'conversion', {
                        'send_to': gtag_signup_conversion_id,
                    });
                } catch (e) {
                }
            }
            if(snapchat_pixel_id){
                try{
                    window.snaptr('track','SIGN_UP')
                }catch(e){

                }
            }
            if (fb_signup_conversion_event) {
                try {
                    window.fbq('track', fb_signup_conversion_event);
                } catch (e) {
                }
            }

            post(endpoint, {
                access_token//, shop: getShop()
            }).catch((e) => {
                console.log(e);
            }).then((ret) => {
                if (ret.access_token) {
                    setAccessToken(ret.access_token);
                    window.location = '/';
                } else if (ret.error) {
                    setErrorCode(ret.code || 'NETWORK')
                }
            });
        } else {
            window.location = '/create-account';
        }
    }, [endpoint, fb_signup_conversion_event, gtag_signup_conversion_id, snapchat_pixel_id])

        return (
            <AuthContainer>
                <div className={'text-center'}>
                    {errorCode ?
                        <RenderError code={errorCode}/> : <Spinner size={"lg"}/>
                    }
                </div>
            </AuthContainer>
        );
}

function mapStateToProps(state, props) {
    const {fb_signup_conversion_event, gtag_signup_conversion_id, snapchat_pixel_id} = state.settings
    return {
        gtag_signup_conversion_id,
        fb_signup_conversion_event: fb_signup_conversion_event || 'AccountCreated',
        snapchat_pixel_id
    };
}


export default connect(mapStateToProps)(OAuthCallback);

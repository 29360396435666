import React, {useMemo, useState} from 'react';
import {EyeClose, EyeOpen} from '../../../icons/Icons';
import {FiCheck, FiX} from 'react-icons/fi';

function isValid(req, value){
  if(req.type === "length"){
    return value.length >= req.value
  }else if(req.type === "number"){
    return value.match(/[0-9]/)
  }else if(req.type === "special"){
    return value.match(/[^a-zA-Z0-9]/)
  }else if(req.type === "uppercase"){
    return value.match(/[A-Z]/)
  }else if(req.type === "lowercase"){
    return value.match(/[a-z]/)
  }
}
function allAreValid(reqs, value){
  if(!reqs || reqs.length === 0) return true
  return reqs.every((req)=>{
    return isValid(req, value)
  })
}
PasswordInput.defaultProps = {
  defaultType: 'password',
}

const toArray = (reqs) => {
  if(reqs){
    const arr = [];
    if(reqs.min_length){
      arr.push({type:"length", value:reqs.min_length, message:reqs.min_length_message})
    }
    if(reqs.min_numbers){
      arr.push({type:"number", value:reqs.min_numbers, message:reqs.min_numbers_message})
    }
    if(reqs.min_special){
      arr.push({type:"special", value:reqs.min_special, message:reqs.min_special_message})
    }
    if(reqs.min_uppercase){
      arr.push({type:"uppercase", value:reqs.min_uppercase, message:reqs.min_uppercase_message})
    }
    if(reqs.min_lowercase){
      arr.push({type:"lowercase", value:reqs.min_lowercase, message:reqs.min_lowercase_message})
    }
    return arr;
  }
}

function PasswordInput({value, requirements: reqs, onChange, defaultType, id, name}) {
  const [type, setType] = useState(defaultType)
  const requirements = toArray(reqs)
  const [val, setVal] = useState(value || '')
  const changed = (e)=>{
    setVal(e.target.value)
    if(allAreValid(requirements, e.target.value)){
      onChange(e)
    }else{
      onChange({target:{value:""}})
    }
  }
  const [touched, setTouched] = useState(false)
  return (
      <div>
        <div className={"position-relative"}>
        <span onClick={() => setType(
            (t) => t === "password" ? "text" : "password")}
              className={"text-muted position-absolute d-inline-flex align-items-center justify-content-center px-2"}
              style={{right: 0, bottom: 0, top: 0}}>
          {
            type === "password" ? <EyeClose/> : <EyeOpen/>
          }
        </span>
          <input
              onBlur={() => setTouched(true)}
              autoComplete={'current-password'}
              id={id} name={name}
              required={true}
              className={'form-control pr-4'} type={type} value={val}
              onChange={changed}/>

        </div>
        {
          (val  || touched) && requirements && requirements.length > 0 ? <div className={"mt-2"}>
        {
          requirements?.map((req, i) => {
            return <ValidateAndRender key={i} type={req.type} value={req.value} inputValue={val} message={req.message}/>
          })
        }</div> : null
        }
      </div>
  );
}


function ValidateAndRender({type, message, value, inputValue}){
  const valid = isValid({type, value}, inputValue)
  if(valid) {
    return <div className={"text-success small d-flex align-items-center gap-2"}><FiCheck />{message}</div>
  }else{
    return <div className={"text-muted small d-flex align-items-center gap-2"}><FiX />{message}</div>
  }
}

export default PasswordInput;

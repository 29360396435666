import {Link} from 'react-router-dom';
import React from 'react';
import {FormattedMessage} from 'react-intl';

export default function RenderError({code}) {
    switch (code) {
        case 'USEREXISTS':
            return (<p className="alert alert-danger">
                <FormattedMessage id={'USEREXISTS'}/>
                <Link to={'/login'} className={'alert-link ml-1'}><FormattedMessage id={'clickHereToLogin'}/>
                </Link>
            </p>);
        case 'INCORRECTPASSWORD':
            return (<p className="alert alert-danger">
                <FormattedMessage id={'INCORRECTPASSWORD'}/>
                <Link to={'/forgot-password'} className={'alert-link'}><FormattedMessage
                    id={'clickHereToResetPwd'}/></Link>
            </p>);
        case 'USERDOESNOTEXISTS':
            return (<p className="alert alert-danger">
                <FormattedMessage id={'USERDOESNOTEXISTS'}/>
                <Link to={'/create-account'} className={'alert-link'}>
                    <FormattedMessage id={'createAnAccount'}/>
                </Link>
            </p>);
        case 'ALLFIELDS':
            return (<p className="alert alert-danger">
                <FormattedMessage id={'You need to fill all the required fields'}/>
            </p>);
        case "RECAPTCHAFAILED":
            return <p className={"alert alert-danger"}>
                <FormattedMessage id={"ReCaptcha check failed. Please submit the form again"}/>
            </p>
        case 'REGISTRATIONNOTALLOWED':
        case 'EMAILREQUIRED':
        case 'LINKEXPIRED':
        case 'EMAILINVALID':
        default:
            return <FormattedError id={code}/>;
    }
}

function FormattedError({id}) {
    return (<p className="alert alert-danger">
        <FormattedMessage id={id || 'NETWORKERROR'}/>
    </p>);
}

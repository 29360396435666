const defaultState = [];

export default (state= defaultState, action)=>{
    switch (action.type){
        case "set-links":
            return action.data;
        case "delete-link":
            return state.filter((item)=>item.id !== action.id)
        case "insert-link":
            return [action.data, ...state]
        default:
            return state;
    }
}

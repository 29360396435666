import React from 'react';
import LazyPageLoader from '../../cmp/LazyPageLoader';

const Page = React.lazy(()=>import('./LandingSectionsIndex'))

export default function LandingSectionsIndex(props){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page />
    </React.Suspense>
}

import LoadingSkeleton from '../LoadingSkeleton';
import React from 'react';

const Page = React.lazy(()=>import('./ContractInput'))

export default function LazyContractInput({
        contractId,
        label,
        value, onChange
  }){
  return <React.Suspense fallback={<LoadingSkeleton height={36.5} />}>
    <Page contractId={contractId}
          label={label}
          value={value}
          onChange={onChange} />
  </React.Suspense>
}

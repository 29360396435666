import React, {Component} from 'react';
import TopNavBar from './pages/landing/components/TopNavBar';
import LandingFooter from './pages/landing/components/LandingFooter';
import LandingContainer from './pages/landing/components/LandingContainer';
import FormattedHelmet from './cmp/intl/FormattedHelmet';
import HeroComponent from './pages/landing/components/HeroComponent';
import LandingThreeCard from './pages/landing/components/LandingThreeCard';
import {connect} from 'react-redux';
import JoinNowBottomButton
  from './pages/landing/components/JoinNowBottomButton';
import HTMLComponent from './pages/help/components/HTMLComponent';
import YoutubeVideoComponent
  from './pages/help/components/YoutubeVideoComponent';

const ComponentsMap = {
    'TopNavBar': TopNavBar,
    'HeroComponent': HeroComponent,
    'LandingThreeCard': LandingThreeCard,
    'JoinNowBottomButton': JoinNowBottomButton,
    'LandingFooter': LandingFooter,
    'HTML': HTMLComponent,
    yt_video: YoutubeVideoComponent,
}

class LandingPage extends Component {
    render() {
        return (
            <LandingContainer>
                <FormattedHelmet id={'pageTitle'}/>
                {
                    this.props.landing_page.map((item) => {
                        const section = this.props.page_sections[item];
                        if (section && section.props && section.props.visible) {
                            const Cmp = ComponentsMap[section.component];
                            if (Cmp) {
                                return <Cmp {...section.props} key={item}/>
                            }
                        }
                    })
                }
            </LandingContainer>
        );
    }
}

function mapStateToProps(state) {
    return {
        hero_btntext: state.settings.hero_btntext !== undefined ? state.settings.hero_btntext : 'joinNow',
        landing_page: state.settings.landing_page ? JSON.parse(state.settings.landing_page) : [
            'default_navigation',
            'default_banner',
            'default_cards',
            'default_bottom_button',
            'default_footer',
        ],
        page_sections: state.settings.page_sections,
        isrtl: state.settings.isrtl,
    }
}

export default connect(mapStateToProps)(LandingPage);

import {useCallback, useState} from 'react';

function useObjectState(initialState = {}){
  const [data, setData] = useState(initialState)
  const changed = useCallback((key)=>{
    return (value)=>{
      setData((d)=>{return {...d, [key]:value}})
    }
  },[])
  return [data, changed, setData]
}

export default useObjectState

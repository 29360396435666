import React from 'react';
import {useParams} from 'react-router-dom';
import LeadCaptureFormComponent
  from './pages/form-referrals/LeadCaptureFormComponent';
import {useSelector} from 'react-redux';

const isEmbedded = window.top !== window.self;

function PublicForm(props) {
  const store_logo = useSelector((state)=>state.settings.store_logo)
  const params = useParams();
  if(isEmbedded){
    return <div className={"container-fluid"}>
      <LeadCaptureFormComponent formId={params.form_id} />
    </div>
  }else{
    return  <div className={"container"}>
      <div className={"text-center"}>
        <img src={store_logo} style={{height:80}} />
      </div>
      <LeadCaptureFormComponent showTitle formId={params.form_id} />
    </div>
  }
}

export default PublicForm;

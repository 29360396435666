import PropTypes from 'prop-types';
import React from 'react';
import {areThirdPartyCookiesEnabled} from '../../../tools/func';

const Page = React.lazy(()=>import('./FacebookChatViaPage'))

export default function LazyFacebookChat(props){
    // facebook chat crashes when third party cookies are disabled
    if(!areThirdPartyCookiesEnabled()){
        return null;
    }
    return <React.Suspense fallback={null}>
        <Page fb_page_id={props.fb_page_id} />
    </React.Suspense>
}

LazyFacebookChat.propTypes = {
    fb_page_id: PropTypes.string.isRequired,
};

import React, {useEffect, useState} from 'react';
import Modal from './cmp/Modal';
import Checkbox from './cmp/form/Checkbox';
import {FormattedMessage} from 'react-intl';
import LegalDocModal, {LegalDocLoader} from './auth/signup/LegalDocModal';
import mustache from 'mustache';

function ScrollReadingModal({onChange, value, isRequired, label}) {
  const onSubmit = (e)=>{
    setChecked(true)
    setVisible(false)
  }
  const [checked, setChecked] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(()=>{
      onChange({
        target:{
          value: checked
        }
      })
  },[checked])


  return (<>
        <Checkbox label={<>
          <FormattedMessage id={label} defaultMessage={label} values={
            {
              terms: text => <LegalDocModal to={"/program-legal/terms"}>{text}</LegalDocModal>,
              privacy: text => <LegalDocModal to={"/program-legal/privacy"}>{text}</LegalDocModal>,
              md: text => <div className={"d-inline"} dangerouslySetInnerHTML={{__html:mustache.render(text)}}/>,
              html:text=><span dangerouslySetInnerHTML={{__html:text}}/>
            }
          }/>
          {isRequired && <span className={"text-danger"}>*</span>}</>} checked={value} value={label} onChange={()=>setVisible(true)}
        />
        <Modal visible={visible}
             size={"lg"}
             okText={"I agree to terms and conditions"}
             onClose={()=>setVisible(false)}
             onCancel={()=>{
               setChecked(false)
               setVisible(false)
             }}
             onOk={onSubmit} title={"Terms and Conditions"}>
                <LegalDocLoader visible={visible} to={"/program-legal/terms"} />
            </Modal>
      </>
  );
}

export default ScrollReadingModal;

import React, {useEffect, useMemo} from 'react';
import {post, removeAccessToken} from '../../../api';
import {connect} from 'react-redux';
import {getCurrentQueryObject} from '../../../tools/func';
import Spinner from '../../cmp/Spinner';

function LogoutPage({isEmbedded}){
    const redirUrl = useMemo(()=>{
        const query = getCurrentQueryObject();
        const pathname = query.to || "/login"
        return isEmbedded ? `${pathname}?embedded=1` : pathname;
    },[isEmbedded])
    useEffect(()=>{
        post('/logout').then((data) => {
            removeAccessToken();
            document.location = redirUrl;
        }).catch(() => {
            document.location = redirUrl;
        });
    }, [redirUrl])
    return (
        <div className={'min-vh-100 d-flex align-items-center justify-content-center'}>
            <Spinner size={"lg"}/>
        </div>
    );
}
function mapStateToProps(state){
    return {
        isEmbedded: state.settings.isEmbedded
    }
}
export default connect(mapStateToProps)(LogoutPage);

import {
  InstagramIcon, PinterestIcon, SnapchatIcon, TikTokIcon,
  TwitterIcon,
  WebsiteIcon,
  YoutubeIcon,
} from '../../icons/Icons';
import React from 'react';

const twitter = (username)=>`https://twitter.com/${username}`
const tiktok = (username)=>`https://twitter.com/@${username}`
const youtube = (username)=>`https://youtube.com/c/${username}`
const instagram = (username)=>`https://instagram.com/${username}`
const pinterest = (username)=>`https://pinterest.com/${username}`
const snapchat = (username)=>`https://snapchat.com/add/${username}`

const socials = {
  twitter,
  tiktok,
  youtube,
  instagram,
  pinterest,
  snapchat,
}

function linkifySocial(url, platformHint){
  if(url.startsWith('https')) return url;
  else {
    const processor =  socials[platformHint];
    if(processor) return processor(url.replace("@",""))
  }
}

export default function RenderUserSocials({affiliate}){
  if(!affiliate) return null;
  return Object.keys(icons).map((key)=>{
    if(affiliate[key]){
      return <RenderSocialIcon key={key} platform={key} url={affiliate[key]}/>
    } else{
      return null;
    }
  })
}
const icons = {
  twitter: TwitterIcon,
  instagram: InstagramIcon,

  partner_website: WebsiteIcon,
  youtube: YoutubeIcon,
  tiktok: TikTokIcon,
  snapchat: SnapchatIcon,
  pinterest: PinterestIcon,

}
function RenderSocialIcon({url, platform}){
  const Icon = icons[platform]
  const href = linkifySocial(url, platform)
  if(Icon && href){
    return <a href={href} target={"_blank"} title={url}><Icon style={{width:'1rem',height:'1rem'}} className={"mr-2 fs-5 text-muted"} /></a>
  }
}

import {getCurrentQueryObject} from '../../tools/func';
import {getAccessToken} from '../../api';

function isEmbedded(){
	return window.top !== window.self
}


function isShopifyAutoLogin(){
	const {is_shopify_login} = getCurrentQueryObject();
	return String(is_shopify_login) === "1"
}

const isPrivacyMode = ()=>{
	try {
		return sessionStorage.getItem('privacyMode')
	}catch(e){

	}
	return false;
}

const defaultSettings = {
	access_token:getAccessToken(),
	privacyMode:isPrivacyMode(),
	name: '',
	email: '',
	instagram: '',
	website: '',
	twitter: '',

	default_currency: '',
	payment_method: '',
	payment_details_data: '{}',
	ref_code: '',
	store_logo: null,
	locale: 'en',
	themePrimaryColor: '#2780E3',

	authGoogle: false,
	authFacebook: false,
	signupName: true,
	signupTwitter: false,
	signupInsta: true,
	signupWebsite: false,
	show_commission_on_landing: true,
	isEmbedded: isEmbedded(),
	isShopifyAutoLogin: isShopifyAutoLogin(),
};

export default function settingsReducer(state = defaultSettings, action){
	switch (action.type) {
		case 'merge-settings':
			//console.log('merging', state, action.settings);
			return { ...state, ...action.settings };
		case "set-commissions":
			const {commissions} = action
			return {...state,
				mlm_enabled: commissions.mlm && commissions.mlm.standard && commissions.mlm.standard.length > 0

			}
		default:
			return state;
	}
};

import React, {useEffect, useRef, useState} from 'react';
import {apiServer, getAccessToken, post} from "../../../api";
import {useNavigate, useParams} from "react-router-dom";

function SAMLLoginComponent(){
    const [data, setData] = useState({
        SAMLResponse: '',
        spAcsUrl: ''
    })
    const params = useParams();
    const providerId = params.providerId
    useEffect(() => {
        if(providerId) {
            post(`${apiServer}/saml/idp/login/${providerId}`, {
                id: params.id
            })
                .then((data) => {
                    setData(data)
                    console.log({data})
                })
        }
    }, [providerId]);

    if(data?.SAMLResponse){
        return <>
            <AutoSubmitForm SAMLResponse={data.SAMLResponse} spAcsUrl={data.spAcsUrl} />
        </>
    }else{
        return <p>Logging in...</p>;
    }

}

function AutoSubmitForm({SAMLResponse, spAcsUrl}){
    const ref = useRef<HTMLFormElement>(null)
    useEffect(() => {
        if(ref?.current){
            ref.current.submit()
        }
    }, [ref?.current]);
    // return <pre>{JSON.stringify({SAMLResponse, spAcsUrl}, null,2)}</pre>
    return <form id={"sso"} ref={ref} method={"post"} action={spAcsUrl} autoComplete={"off"}>
        <input type={"hidden"} name={"SAMLResponse"} id={"resp"} value={SAMLResponse}/>
    </form>
}


function SamlLogin() {
    const navigate = useNavigate();
    const access_token = getAccessToken();
    useEffect(() => {
        if (!access_token) {
            return navigate('/login?next=' + encodeURIComponent(window.location.href), {
                replace: true
            })
        }
    }, [access_token]);
    if (access_token) {
        return <SAMLLoginComponent/>
    }else{
        return null;
    }
}

export default SamlLogin;

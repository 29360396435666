import React, {useCallback, useEffect, useState} from 'react';
import {getAccessToken, post} from '../../api';
import StyledButton from '../cmp/themed/StyledButton';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import RenderError from './components/RenderError';
import {useParams} from 'react-router-dom';
import Spinner from '../cmp/Spinner';

function VerifyEmail() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const params = useParams();
    const verifyEmail = useCallback(() => {
        setLoading(true)
        setError(null)
        post('/verifyemail', {
            code: params.hash
        }).then((e) => {
            if (e.success) {
                setLoading(false)
            } else {
                setLoading(false)
                setError(true)
            }
        }).catch((e) => {
            setLoading(true)
            this.setState({loading: false});
        });
    },[params.hash])
    useEffect(()=>{
        verifyEmail();
    },[verifyEmail])

    const toDashboard = useCallback(() => {
        if(getAccessToken()){
            window.location = '/';
        }else{
            window.location = "/login"
        }
    },[])


        const cmp = error ? (<div>
                <RenderError code={error}/>
                <button className={'btn btn-outline-primary'} onClick={verifyEmail}>
                    <FormattedMessage id={'tryAgain'}/>
                </button>
            </div>) :
            (<div>
                <FormattedMessage tagName={'h1'} id={'emailVerified'}/>
                <FormattedMessage tagName={'p'} id={'emailVerifiedThanks'}/>
                <StyledButton className={'btn btn-primary'} onClick={toDashboard}>
                    <FormattedMessage id={'returnToDashboard'}/></StyledButton>
            </div>);

        return (
            <div className={'bg-light'}>
                <FormattedHelmet id={'verifyingEmail'}/>
                <div className={'container-fluid'}>
                    <div className={'row justify-content-center align-items-center  min-vh-100'}>
                        <div className={'card col-md-4 shadow-md'}>
                            <div className={'card-body'}>
                                {
                                    loading ? <div className={'my-4 text-center'}>
                                        <FormattedMessage tagName={'h1'} id={'verifyingEmail'}/>
                                        <Spinner height={'1rem'} width={'1rem'}  />
                                    </div> : cmp
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default VerifyEmail;

import LazyPageLoader from '../../cmp/LazyPageLoader';
import React from 'react';

const Page = React.lazy(()=>import('./QRCodeInternal'))

export default function LazyStoreIndex(props){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page />
    </React.Suspense>
}

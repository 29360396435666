import React, {useEffect, useState} from 'react';
import {useLocalStorageState} from '../../../tools/storage';
import {get} from '../../../api';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {getCurrentQueryObject} from '../../../tools/func';

export default function SponsorName() {
    const mlm_show_referred_by = useSelector((state)=>state.settings.mlm_show_referred_by)
    const [name, setName]= useState(null);
    const ref = useLocalStorageState((state)=>state.ref || getCurrentQueryObject()?.ref)
    useEffect(()=> {
        if (ref) {
            const abortController = new AbortController()
            get('/who_is_the_referrer', {ref}, abortController)
            .then(({name}) => {
                setName(name)
            })
            .catch(e => {
            });
            return ()=>{
                abortController.abort()
            }
        }else{
            setName(null)
        }
    },[ref])
    if(!mlm_show_referred_by){
        return null;
    }
    if(!name) return null;
    return <div className={'text-muted ml-4'}>
        <small><FormattedMessage id="Referred by"/></small>
        <div>{name}</div>
    </div>
}

import React, {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';

function Select({value, options, onChange, hasEmpty}) {
  const changed = useCallback((e)=>{
    if(onChange){
      onChange(e.target.value)
    }
  },[onChange])

  return (
      <select className={"form-select"} value={value} onChange={changed}>
        {
          hasEmpty ? <option/> : null
        }
        {
          options.map(({label, value})=>{
            return <Opt value={value} id={label} key={value}/>
          })
        }
      </select>
  );
}

function Opt({value, id}) {
  if(!id) return <option value={value}>{id}</option>
  return <FormattedMessage id={id}>{txt => <option value={value}>{txt}</option>}</FormattedMessage>
}

export default Select;

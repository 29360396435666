import LoadingSkeleton from '../LoadingSkeleton';
import React from 'react';

const Page = React.lazy(()=>import('./W9FormInput'))

export default function LazyW9FormInput(props){
  return <React.Suspense fallback={<LoadingSkeleton height={36.5} />}>
    <Page {...props} />
  </React.Suspense>
}

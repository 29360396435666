import React, {useCallback, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import OAuthSignIn from './OAuthSignIn';
import {post, setAccessToken} from '../../api';
import AuthContainer from './components/AuthContainer';
import LogoRender from '../cmp/LogoRender';
import StyledButton from '../cmp/themed/StyledButton';
import FormInput from './components/FormInput';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import {FormattedMessage} from 'react-intl';
import RenderError from './components/RenderError';
import {useSelector} from 'react-redux';
import {getCurrentQueryObject} from '../../tools/func';
import RecaptchaRenderer from '../cmp/RecaptchaRenderer';
import PasswordInput from '../cmp/form/PasswordInput';
import useObjectState from '../cmp/hooks/useObjectState';

const reducer = (state)=>{
    const {allow_partner_registrations, isEmbedded, recaptcha_enabled} = state.settings
    return {
        isEmbedded,
        allow_partner_registrations,
        recaptcha_enabled,
    }
}

export default function LoginPage() {
    const {
        isEmbedded,
        allow_partner_registrations,
        recaptcha_enabled,
    } = useSelector(reducer)
    const [data, setData] = useObjectState({
        email:'',
        password: '',
        recaptcha_response:null,
    })
    const [loading, setLoading] = useState(false)
    const [code, setErrorCode] = useState('')
    const handleFormSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const partner_portal_subdomain = document.location.host.startsWith('localhost') ? 'goaffprotest1.goaffpro.com' : document.location.host;
        const {email, password, recaptcha_response} = data
        setLoading(true)
        setErrorCode(null)
        post('/login', {
            email, password,
            partner_portal_subdomain,
            recaptcha_response
        }).then((data) => {
            setLoading(false)
            if (data.access_token) {
                setAccessToken(data.access_token);
                const {next} = getCurrentQueryObject();
                if (next) {
                    window.location = next;
                } else {
                    window.location = `/${isEmbedded ? '?embedded=1' : ''}`;
                }
            } else {
                setErrorCode(data.code)
            }
        }).catch((e) => {
            setLoading(false)
        });
    }
    const changed = useCallback((key)=>{
        return (e)=>setData(key)(e.target.value)
    },[setData])

    const canSubmit = useMemo(()=>{
        if(recaptcha_enabled && !data.recaptcha_response){
           return false;
        }
        return data.email && data.password
    },[data.email, data.password, data.recaptcha_response, recaptcha_enabled])
     return (
            <AuthContainer>
                <FormattedHelmet id={'Login'}/>
                <div className={'text-center mb-4'}>
                    <LogoRender/>
                </div>
                <h1 className={"text_on_bg"}><FormattedMessage id={'Login'}/></h1>
                <div className={'card shadow-sm'}>
                    <div className={'card-body'}>
                        {
                            code && <RenderError code={code}/>
                        }
                        <form onSubmit={handleFormSubmit}>
                            <FormInput id={'emailAddress'}>
                                <input type={'email'} autoComplete={'email'}

                                       value={data.email} name={'email'}
                                       onInput={changed('email')} className={'form-control'}/>
                            </FormInput>
                            <FormInput id={'password'}>
                                <PasswordInput
                                    name={"password"}
                                    value={data.password} onChange={changed('password')} />
                            </FormInput>
                            <RecaptchaRenderer onChange={changed('recaptcha_response')}/>
                            <div className={'row justify-content-between align-items-center'}>
                                <div className={'col'}>
                                    <StyledButton loading={loading} disabled={!canSubmit} onClick={handleFormSubmit}                                                       className={'btn btn-primary'}><FormattedMessage id={'Login'}/></StyledButton>
                                </div>
                                <div className={'col-auto'}>
                                    <Link to={'/forgot-password'}>
                                        <FormattedMessage id={'forgotPassword'}/>
                                    </Link>
                                </div>
                            </div>
                        </form>

                        <OAuthSignIn/>

                    </div>
                </div>
                {
                    allow_partner_registrations ?
                    <div className={'mt-4 text-center text_on_bg'}>
                        <span><FormattedMessage id={'dontHaveAccount'}/></span>
                        <Link to={'/create-account'} className={'ml-1'}><FormattedMessage id={'createAccount'}/></Link>
                    </div> : null
                }
            </AuthContainer>
        );
}

import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {nanoid} from 'nanoid';

function RadioGroup({onChange, options, checked}) {
    const prefix = useMemo(()=>{
        return nanoid(10)
    },[])
    const onClick = (value) => (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        onChange({target: {value}});
    }
    return (
        <div className={'d-flex flex-wrap'}>
            {
                options && options.map((item, index) => (
                    <span className={'pr-2 mr-2'} key={index}>
                    <input type={'radio'} value={item} name={prefix+""+item} onChange={onClick(item)}
                           checked={item === checked}/>
                    <label htmlFor={prefix+""+item} className={'pr-4 pl-1'} onClick={onClick(item)}>{item}</label>
                </span>))

            }
        </div>
    );
}

RadioGroup.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.string.isRequired
};

export default RadioGroup;

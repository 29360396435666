import React from 'react';
import LoadingSkeleton from "../../cmp/LoadingSkeleton";

const CountrySelectorInternal = React.lazy(()=>import("./CountrySelectorInternal"));

function CountrySelector(props) {
    return (
        <React.Suspense fallback={<LoadingSkeleton height={48} />}>
            <CountrySelectorInternal {...props} />
        </React.Suspense>
    );
}

export default CountrySelector;

import React, {lazy} from 'react';
import LazyPageLoader from '../../cmp/LazyPageLoader';

const Component = lazy(() => import('./SubscribePayment_Internal'));
function SubscribePayment(props) {
  return (
      <React.Suspense fallback={<LazyPageLoader />}>
          <Component {...props} />
      </React.Suspense>
  );
}

export default SubscribePayment;

import React from 'react';
import LazyPageLoader from '../../cmp/LazyPageLoader';
const Component = React.lazy(() => import('./CreateAccountClassic_Internal'));
function CreateAccountClassic(props) {
  return (
      <React.Suspense fallback={<LazyPageLoader />}>
          <Component {...props} />
      </React.Suspense>
  );
}

export default CreateAccountClassic;

import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import CurrencyNumber from '../../cmp/CurrencyNumber';
import {get} from '../../../api';
import Spinner from '../../cmp/Spinner';
import {useParams} from 'react-router-dom';

const reducer = (state)=>{
    return {
        store_name: state.settings.store_name,
        website: state.settings.website,
    }
}
export default function CouponPage() {
    const [data, setData] = useState({})
    const {store_name, website} = useSelector(reducer)
    const [loading, setLoading] = useState(false)
    const params = useParams();
    const coupon = useMemo(()=>params.coupon,[params]);
    const [error, setError] = useState(null)
    useEffect(() => {
        setLoading(true)
        get('/coupon_details?coupon=' + coupon).then((data) => {
            setLoading(false)
            setData(data)

        }).catch(() => {
            setError('NETWORK ERROR')
            setLoading(false)
        })

    }, [coupon])
        const {discount_type, discount_value, affiliate_name} = data;
        if (loading) {
            return <div className={"bg-light min-vh-100"}>
                <div className={"text-center"} style={{paddingTop: 100}}>
                    <Spinner size={"lg"}/>
                </div>
            </div>
        }
        if (error) {
            return <div className={"bg-light min-vh-100"}>
                <div className={"text-center"} style={{paddingTop: 100}}>
                    <p className={"alert alert-danger"}>{error}</p>
                </div>
            </div>
        }

        const discount = discount_type === "percentage" ? discount_value + "%" : <CurrencyNumber value={discount_value}/>

        return (

            <div className={"bg-light min-vh-100"}>

                <div className={"row"} style={{paddingTop: 100}}>

                    <div className={"col-lg-4"}/>

                    <div className={"col-lg-4"}>

                        <div className="card shadow-lg">

                            <div className="card-body">

                                <div className={"text-center"}>

                                    <img src={"/images/gift.svg"} alt={"COUPON"} className={"mb-2"} width={"50%"}

                                         height={"50%"}/>

                                    <h2>

                                        <FormattedMessage id={"discount_page_heading"}

                                                          defaultMessage={"Here is your {discount} OFF"} values={{

                                            discount

                                        }}/>

                                    </h2>

                                    <p className={"text-muted"}>

                                        <FormattedMessage id={"discount_page_text"}

                                                          defaultMessage={"{affiliate_name} thinks you'll love {store_name}, so here's {discount} off!"}

                                                          values={{

                                                              store_name: store_name,

                                                              affiliate_name: affiliate_name,

                                                              discount

                                                          }}



                                        />

                                    </p>

                                    <input type="text"

                                           className="form-control form-control-lg mt-4 text-center text-uppercase"

                                           value={coupon}/>

                                    <a target={"_blank"} rel={"noopener nofollow"}

                                       href={`https://${website}/discount/${coupon}`}

                                       className={"btn btn-primary btn-lg"} style={{width: '100%'}}>

                                        <FormattedMessage id={"SHOP NOW"}/>

                                    </a>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className={"col-lg-4"}/>

                </div>

            </div>

        );

}


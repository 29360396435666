import React, {useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import {EyeClose, EyeOpen} from '../../../icons/Icons';
import PasswordInput from './PasswordInput';

const InputMask = React.lazy(()=>import('react-input-mask'))
function MaskedInput({mask, value, onChange, autoComplete, id, name, type}) {
    const [t, setType] = useState(type)
    if(mask){
        return <React.Suspense fallback={<Skeleton height={36.5}/>}>
            <div className={"position-relative"}>
        <span onClick={() => setType(
            (t) => t === "password" ? "text" : "password")}
              className={"text-muted position-absolute d-inline-flex align-items-center justify-content-center px-2"}
              style={{right: 0, bottom: 0, top: 0}}>
          {
              t === "password" ? <EyeClose/> : <EyeOpen/>
          }
        </span>
                <InputMask mask={mask} maskChar={"_"}
                           onChange={onChange}
                           type={t} id={id} name={name} value={value}
                           autoComplete={autoComplete}
                           alwaysShowMask={true}
                           className={'form-control'}
                />
            </div>

        </React.Suspense>
    } else {
        return type === "password" ? <PasswordInput
                type={type} id={id} name={name} value={value}
                onChange={onChange}
                autoComplete={autoComplete}
            />
            : <input type={type} id={id} name={name} value={value}
                      onChange={onChange}
                      autoComplete={autoComplete}
                      className={'form-control'}
        />
    }
}

export default MaskedInput;

import {nanoid} from 'nanoid';
import {getItem, setItem} from '../../../../tools/storage';

const l = getItem("goaffpro_cart"); // don't use "cart" as the key as shopify also set "cart" cookie and this can interfere if the affiliate portal is on a subdomain
const defaultState = l ? JSON.parse(l) : [];

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'set-cart':
            setItem('goaffpro_cart', JSON.stringify(state))
            return action.data;
        case "add-to-cart":
            const item = action.item;
            const {platform_product_id, variant} = action.item;
            const found = state.find((itm) => itm.platform_product_id === platform_product_id && itm.variant.platform_variant_id === variant.platform_variant_id);
            if (!found) {
                item.item_id = nanoid(10)
                const st = [...state, item];
                setItem("goaffpro_cart", JSON.stringify(st));
                return st;
            } else {
                return state;
            }
        case "remove-from-cart": {
            const st = state.filter((itm) => action.item_id !== itm.item_id);
            setItem("goaffpro_cart", JSON.stringify(st));
            return st;
        }
        case "update-item-quantity": {
            return state.map((itm) => {
                if (itm.item_id === action.item_id) {
                    return {...itm, quantity: action.quantity}
                } else {
                    return itm;
                }
            })
        }
        default:
            return defaultState;
    }
}

import React from 'react';
import {connect} from 'react-redux';
import Reaptcha from 'reaptcha';
import FormGroup from './form/FormGroup';

function RecaptchaRenderer({onChange, recaptcha_enabled, recaptcha_site_key}){

    const onVerified = (response) => {
        if (onChange) {
            onChange({target: {value: response}});
        }
    }
    if (!recaptcha_enabled) return null;
    return (<FormGroup>
            <Reaptcha sitekey={recaptcha_site_key} onVerify={onVerified}/>
        </FormGroup>
    );
}

function mapStateToProps(state) {
    return {
        recaptcha_enabled: state.settings.recaptcha_enabled,
        recaptcha_site_key: state.settings.recaptcha_site_key || '6Lf_jsQUAAAAAOLW40PpDXgZQDIjjnGldAE1fhYr',
        locale: state.settings.locale,
    }
}

export default connect(mapStateToProps)(RecaptchaRenderer);

import React, {useCallback} from 'react';
import Skeleton from 'react-loading-skeleton';

const MaskedInput = React.lazy(()=>import('react-input-mask'))

function Input(props) {
  const onChange = props.onChange
  const changed = useCallback((e)=>{
    if(onChange){
      onChange(e.target.value)
    }
  },[onChange])
  if(props.mask){
    return <React.Suspense fallback={<Skeleton height={36.5}/>}>
      <MaskedInput
          className={"form-control"}  {...props}
          mask={props.mask} maskChar={"_"}
          onChange={changed}/>
    </React.Suspense>
  }
  return (
      <Wrapper prefix={props.prefix} suffix={props.suffix}>
        <input className={"form-control"} {...props} onChange={changed}/>
      </Wrapper>
  );
}

function Wrapper({prefix, children, suffix}){
  if(prefix || suffix){
    return <div className="input-group">
      {
          prefix && <span className="input-group-text">{prefix}</span>

      }
      {children}
      {
          suffix && <span className="input-group-text">{suffix}</span>
      }
    </div>
  }
  return children
}


export default Input;

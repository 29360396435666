import {useCallback} from 'react';
import {post} from '../../../api';
import RenderForm from './RenderForm';

export default function LeadCaptureFormComponent({formId, formData, onSubmit}){
  const submitLead = useCallback((values)=>{
    const ref_code = new URL(window.location.href).searchParams.get('ref')
    return post('/form-lead/'+formId, {
          values,
          ref: ref_code
        })
        .then((result)=>{
          if(onSubmit) {
            onSubmit()
          }
          return result;
      })
  },[formId, onSubmit])
  return <>
    <RenderForm onSubmit={submitLead} formId={formId} formData={formData} />
  </>
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

class RenderLegalDocLink extends Component {

    render() {
        const {legal_url, title, style} = this.props;
        if (!legal_url.startsWith('http')) {
            return <Link to={legal_url} style={style}><FormattedMessage id={title}/></Link>;
        }
        return <a href={legal_url} title={title} style={style} target={'_blank'}><FormattedMessage id={title}/></a>;
    }
}

function mapStateToProps(state, props) {
    const {type} = props;
    const doc_url = state.settings[type];
    return {
        legal_url: !doc_url || doc_url.startsWith('https://static.goaffpro.com') ? type === 'terms_url' ? '/program-legal/terms' : '/program-legal/privacy' : doc_url,
        type,
        title: type === 'terms_url' ? 'footerTerms' : 'privacyPolicy'
    };
}

export default connect(
    mapStateToProps
)(RenderLegalDocLink);

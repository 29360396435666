import React, {useEffect} from 'react';
import LazyPageLoader from './cmp/LazyPageLoader';
import {useSelector} from "react-redux";

const Page = React.lazy(()=>import('./Dashboard'))

export default function CustomPageLazy(){
    // useAutoPushReferralCodeToParent()
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page />
    </React.Suspense>
}




// function useAutoPushReferralCodeToParent(){
//     const ref_code = useSelector((state: any)=>state.settings.ref_code)
//     useEffect(()=>{
//         if(window.top && window.top !== window.self){
//             window.top.dispatchEvent(new CustomEvent('goaffproReferralCode',{
//                 detail:{
//                     ref: window.location.href
//                 }
//             }))
//         }
//     },[ref_code])
// }

import React from 'react';
import LazyPageLoader from '../../../cmp/LazyPageLoader';

const Page = React.lazy(()=>import('./DisplayContract'))

export default function LazyContractPage(){
    return <React.Suspense fallback={<LazyPageLoader />}>
        <Page/>
    </React.Suspense>
}

import {useLocation} from 'react-router-dom';
import qs from 'query-string';

export default function useQuery(){
    const location = useLocation();
    return qs.parse(location.search)
}


export function appendToQuery(location, obj = {}){
    return location.pathname+"?"+qs.stringify({...qs.parse(location.search),...obj })
}

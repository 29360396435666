import React, {useCallback, useEffect, useState} from 'react';
import {get} from '../../api';
import Spinner from '../cmp/Spinner';
import CreateAccountModern from './signup/CreateAccountModern';
import CreateAccountSleek from './signup/CreateAccountSleek';
import CreateAccountClassic from './signup/CreateAccountClassic';
import {useParams} from 'react-router-dom';

function CreateAccountExtraPages(){
    const [loading, setLoading]= useState(true)
    const [pageData, setPageData]= useState(null)
    const [notFound, setNotFound] = useState(false)
    const params = useParams()

        useEffect(()=> {
        //fetch signup page
        setLoading(true)
        get('/signup_page', {
            handle: params.handle
        }).then(({page_data}) => {
            const {type} = JSON.parse(page_data.signupData || '{}');
            setPageData({
                page_data,
                type
            })
            setLoading(false)
        }).catch((e) => {
            setLoading(false)
            if (e.status === 404) {
                setNotFound(true)
            }
        })

    },[params.handle])

    const getComponent = useCallback(() => {
        if(!pageData) return null;
        switch (pageData.type) {
            case "modern":
                return CreateAccountModern;
            case "classic":
                return CreateAccountClassic;
            default:
                return CreateAccountSleek;
        }
    },[pageData])
        if (loading) {
            return <div className={"min-vh-100 w-100 d-flex align-items-center justify-content-center"}>
                <Spinner size={'lg'}/>
            </div>
        }
        if (notFound) {
            return <div className={"min-vh-100 w-100 d-flex align-items-center justify-content-center"}>
                <div>
                    <div className={"display-1 text-center"}>404</div>
                    <div className={"text-center"}>PAGE NOT FOUND</div>
                </div>
            </div>
        }
        const {page_data} = pageData || {};
        if (!page_data) {
            return <div className={"min-vh-100 w-100 d-flex align-items-center justify-content-center"}>
                <div>
                    <div className={"text-center"}>PAGE NOT CONFIGURED YET</div>
                </div>
            </div>
        }
        const Cmp = getComponent()
        return (
            <>
                <Cmp page_data={page_data} page_handle={params.handle}/>
            </>
        );
}

export default CreateAccountExtraPages;

import React, {Component} from 'react';
import LogoRender from '../../cmp/LogoRender';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import FormattedHelmet from '../../cmp/intl/FormattedHelmet';
import RenderLegalDocLink from '../../cmp/RenderLegalDocLink';
import AccountCreationForm from './AccountCreationForm';
import AuthContainer from '../components/AuthContainer';
import {Link} from 'react-router-dom';

class CreateAccount extends Component {

    state = {
        loading: false,
        error: null
    }

    render() {

        return (
            <AuthContainer>
                <FormattedHelmet id={this.props.signupPageTitle}/>
                <div>
                    <div className={'text-center mb-4'}>
                        <LogoRender/>
                    </div>
                    <h1 className={"text_on_bg "}>
                        <FormattedMessage id={this.props.signupPageTitle}/>
                    </h1>

                    <div className="card shadow-sm mt-2">
                        <div className="card-body">
                            <AccountCreationForm page_data={this.props.page_data} page_handle={this.props.page_handle}/>
                        </div>
                    </div>
                </div>

                <small className={'d-block text-muted mt-4 px-4 text-center'}>
                    <FormattedMessage id={'tosAgree'}/>
                </small>
                <div className="mt-4 text-center">
                    <FormattedMessage id={'alreadyHaveAccount'}/>
                    <Link to={'/login'} className={'ml-1'}><FormattedMessage id={'Login'}/></Link>
                </div>
                <CreateAcctFooter isEmbedded={false}/>
            </AuthContainer>
        );
    }
}

function CreateAcctFooter({isEmbedded}) {

    return (<div>
        {
            !isEmbedded && <hr/>
        }
        {
            !isEmbedded &&
            <div className={'text_on_bg mt-4 mb-4 d-flex justify-content-between'}>
                <RenderFooterUrl type={'terms_url'}/>
                <RenderFooterUrl type={'privacy_url'}/>
            </div>
        }
    </div>);
}


function RenderFooterUrl({type}) {
    return (<small><RenderLegalDocLink type={type}/></small>);
}


function mapStateToProps(state, props) {
    const {isEmbedded, signupData, signupPageTitle} = props.page_data ? props.page_data : state.settings;
    return {
        signupData: signupData ? JSON.parse(signupData) : {
          type:'sleek'
        },
        signupPageTitle: signupPageTitle || 'Create Account',
        isEmbedded
    };
}

export default connect(mapStateToProps)(CreateAccount);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import CurrencyNumber from '../../../cmp/CurrencyNumber';

class DefaultCommissionRender extends Component {
    render() {
        const {default_commission_type, default_commission_value} = this.props;
        switch (default_commission_type) {
            case 'percentage':
                return `${default_commission_value}%`;
            default:
                return <CurrencyNumber value={default_commission_value}/>;
        }
    }
}

function mapStateToProps(state) {
    const {default_commission_type, default_commission_value} = state.settings;
    return {
        default_commission_value,
        default_commission_type
    };
}

export default connect(
    mapStateToProps
)(DefaultCommissionRender);

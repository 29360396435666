import React from 'react';
import Label from '../../auth/components/Label';

export default function FormGroup({children, label, required, htmlFor, info}:{
    children: React.ReactNode,
    label?: string,
    required?: boolean,
    htmlFor?: string,
    info?: string
}){
    return <div className="form-group">
        {
            label ? <Label title={label} htmlFor={htmlFor} required={required}/> : null
        }
        {children}
        {
            info && <small className={"text-muted"}>{info}</small>
        }
    </div>
}

import React, {useCallback, useMemo} from 'react';
import {
  facebookShare,
  openShareWindow,
  pinterestShare,
  twitterShare,
  whatsAppShare,
} from '../../tools/func';
import CopyButton from './CopyButton';
import SocialShareIcon from './SocialShareIcon';
import PropTypes from 'prop-types';
import QrCodeModal from './qrcodeloader/QRCodeModal';
import {useSelector} from 'react-redux';
import {AiOutlineQrcode, AiOutlineWhatsApp} from 'react-icons/ai';
import {IoLogoPinterest} from 'react-icons/io5';
import {FiMail, FiShare2} from 'react-icons/fi';
import useToggle from './hooks/useToggle';

function ShareBar({link, noCopy}) {
    const [showQrModal, toggle] = useToggle()
    const canShare = useMemo(()=>{
        const data = {
            url:link
        }
        return 'canShare' in navigator && navigator.canShare(data)
    },[link])
    const store_logo = useSelector((state)=>state.settings.store_logo)
    const doShare = useCallback((type)=> {
        return ()=>{
            switch (type){
                case "facebook":
                    openShareWindow(facebookShare(link), "facebook", "width=500, height=500")
                break;
                case "twitter":
                    openShareWindow(twitterShare(link), 'twitter', 'width=600,height=300');
                break;
                case "pinterest":
                    openShareWindow(pinterestShare(link, '', store_logo), "pinterest", "width=600,height=300")
                break;
                case "whatsapp":
                    window.open(whatsAppShare(link))
                break;

            }
        }
    },[link])

    const navigatorShare = ()=>{
        const data = {
            url:link
        }
        if('canShare' in navigator && navigator.canShare(data)) {
            navigator.share(data).catch((e)=>{
                console.log(e)
            })
        }
    }
    const shareOnMail = ()=>{
        window.open('mailto:?body='+link)
    }

    return (
        <div className="d-flex">
            {
                !noCopy ?
                <CopyButton style={{width:65, textAlign:'left'}} text={link}/> : null
            }
            <div>
                {
                    canShare ?
                        <SocialShareIcon
                             Cmp={<FiShare2 style={{width:22, height:22}} />}
                            disabled={!link} onClick={navigatorShare} alt={"Facebook"}
                            src={"/images/facebook.svg"}/> : null
                }
                <SocialShareIcon
                  //  Cmp={<IoLogoFacebook style={{width:24, height:24}} />}
                    disabled={!link} onClick={doShare('facebook')} alt={"Facebook"}
                                 src={"/images/facebook.svg"}/>
                <SocialShareIcon
                //    Cmp={<IoLogoTwitter style={{width:24, height:24}} />}
                    disabled={!link} onClick={doShare('twitter')} alt={"X (formerly Twitter)"}
                                 src={"/images/x-twitter.svg"}/>
                <SocialShareIcon
                    Cmp={<IoLogoPinterest style={{width:22, height:22}} />}
                    disabled={!link} onClick={doShare('pinterest')} alt={"Pinterest"}
                                 src={"/images/pinterest.svg"}/>
                <SocialShareIcon
                    Cmp={<AiOutlineWhatsApp style={{width:22, height:22}} />}
                    disabled={!link} onClick={doShare('whatsapp')} alt={"WhatsApp"}
                                 src={"/images/whatsapp.svg"}/>
                <SocialShareIcon
                    Cmp={<AiOutlineQrcode style={{width:22, height:22}} />}
                     disabled={!link} onClick={toggle} alt={"QR Code"}
                                 src={"/images/qr-code.svg"}/>
                <SocialShareIcon
                    Cmp={<FiMail style={{width:22, height:22}} />}
                    alt={"Mail"}
                    disabled={!link} onClick={shareOnMail}
                    src={"/images/qr-code.svg"}/>
            </div>
            <QrCodeModal value={link} onClose={toggle} visible={showQrModal}/>
        </div>
    );
}

ShareBar.propTypes = {
    link: PropTypes.string.isRequired
}

export default ShareBar;

import {CookieStorage} from 'cookie-storage';
import {create} from 'zustand';

const cookieStorage = new CookieStorage();

export function setItem(key, value){
	try {
		if (localStorage) {
			localStorage.setItem(key, value);
		}
	}catch(e){

	}
	cookieStorage.setItem(key, value);
}
export function getItem(key){
	try{
		return (localStorage && localStorage.getItem(key)) || cookieStorage.getItem(key);
	}catch(e){

	}
}


export function removeItem(key){
	try {
		if (localStorage) {
			localStorage.removeItem(key);
		}
	}catch(e){

	}
	cookieStorage.removeItem(key);
}


export const useLocalStorageState = create((set)=>{
	return {
		ref:null,
		setItem:(key, value)=>{
			if(value === null){
				removeItem(key)
			}else {
				setItem(key, value);
			}
			set({
				[key]:value
			})
		},
		getItem:(key)=>{
			return getItem(key)
		},
		removeItem:(key)=>{
			removeItem(key)
			set({
				[key]:null
			})
			return null;
		}
	}
})

const store = useLocalStorageState.setState;
const obj = {}
try {
	for (var i = 0, len = localStorage.length; i < len; ++i) {
		const key = localStorage.key(i);
		const value = localStorage.getItem(key);
		obj[key] = value;
	}
}catch(e){

}

store(obj)

const development = process.env.NODE_ENV !== 'production';

const oauth_proxy = process.env.NODE_ENV === 'development' ? 'http://api.goaffpro.com:8000/oauthproxy.html' : 'https://api.goaffpro.com/oauthproxy.html';


export const googleAuthConfig = {
	oauth_proxy, //proxies the request to a single domain
	client_id: '1077926910375-peqc9f7e901pic6te6vvvmc7amhdd5lu.apps.googleusercontent.com',
	callback_uri: development ? 'http://localhost:8080/auth/google' : `https://${document.location.host}/auth/google`

};

export const facebookAuthConfig = {
	oauth_proxy:'https://static.goaffpro.com/oauthproxy.html',
	client_id: '576763459459768',
	callback_uri: development ? 'http://localhost:3001/auth/facebook': `https://${document.location.host}/auth/facebook`
};


export function getShop(){
	return development ? 'goaffprotest1.goaffpro.com' :  document.location.host;
}

export function noop() {

}

import React, {useCallback, useMemo, useState} from 'react';
import AuthContainer from './components/AuthContainer';
import FormattedHelmet from '../cmp/intl/FormattedHelmet';
import LogoRender from '../cmp/LogoRender';
import {FormattedMessage} from 'react-intl';
import RenderError from './components/RenderError';
import FormInput from './components/FormInput';
import StyledButton from '../cmp/themed/StyledButton';
import Checkbox from '../cmp/form/Checkbox';
import {post, setAccessToken} from '../../api';
import {useParams} from 'react-router-dom';
import {getItem} from '../../tools/storage';

export default function InvitationPage() {
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(true)
    const [errorCode, setErrorCode] = useState(null)
    const params = useParams()
    const handleFormSubmit = useCallback((e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setLoading(true)
        const utm_source = getItem('utm_source');
        post('/invite-register', {
            invite_key:  params.invitation_key,
            password,
            utm_source,
        }).then((data) => {
            setLoading(false)
            if (data.access_token) {
                setAccessToken(data.access_token);
                window.location = `/`;
            } else {
                const {code} = data;
                setErrorCode(code)
            }
        }).catch(() => {
            setLoading(false)
        });
    },[params.invitation_key, password])

    const togglePasswordVisibility = useCallback((e) => {
        setShowPassword((p)=>!p)
    },[])

    const isEnabled = useMemo(() => password && password.length > 3,[password])
    return (
        <AuthContainer>
            <FormattedHelmet id={'Create Password'}/>
            <div className={'text-center mb-4'}>
                <LogoRender/>
            </div>
            <h1><FormattedMessage id={'Create Password'}/></h1>
            <div className={'card shadow-sm'}>
                <div className={'card-body'}>
                    {
                        errorCode && <RenderError code={errorCode}/>
                    }
                    <form onSubmit={handleFormSubmit}>
                        <FormInput id={'password'}>
                            <input type={showPassword ? 'text' : 'password'} autoComplete={'current-password'}
                                   value={password} name={'password'} onChange={(e)=>setPassword(e.target.value)}
                                   className={'form-control'}/>
                        </FormInput>
                        <Checkbox label={<FormattedMessage id={'Show password'} />} checked={showPassword}
                                  onChange={togglePasswordVisibility} value={'show_password'}/>
                        <StyledButton
                            loading={loading}
                            disabled={!isEnabled}
                            onClick={handleFormSubmit}
                            className={'btn btn-primary'}>
                            <FormattedMessage id={'Login'}/>
                        </StyledButton>
                    </form>
                </div>
            </div>
        </AuthContainer>
    );
}
